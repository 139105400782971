import React, { useEffect, useState } from "react"
import {
    ModalContent,
    ModalActions,
    Icon,
    ModalHeader,
    Modal, ut, GridColumn, Grid
} from "semantic-ui-react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { gql, useQuery, useMutation } from "@apollo/client";
import GlobalContext from "../../GlobalContext";
import Women from "../../Assets/Images/Women2.jpeg"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from "react-responsive-carousel";
import "../../Assets/css/SecondCategory.css"
import userId from "../../UserId";
import { Link } from "react-router-dom";
import { SuccessAlert } from "./SuccessAlert";
import { IoMdClose } from "react-icons/io";
import { FaHeart } from "react-icons/fa";
import { CiHeart } from "react-icons/ci";
import { BabelLoading } from "react-loadingg";
import 'semantic-ui-css/semantic.min.css'
import zIndex from "@mui/material/styles/zIndex";

const GET_PRODUCTS = gql`
query Products($filter: productfilter) {
    products(filter: $filter) {
      name
      tax
      prize
      id
      shopId
      discount
      productId
      quantity {
        quantity
      }
      isAddedToCart {
        inCart
      }
      wishList {
        like
      }
      productImage {
        id
        image
        productId
      }
      featureImage
      description
    }
  }
`;

const UPDATE_PRODUCT = gql`
mutation Mutation($productId: Int, $shopId: Int, $userId: Int, $update: Boolean, $quantity: Int) {
    Cart(productId: $productId, shopId: $shopId, userId: $userId, Update: $update, quantity: $quantity) {
      productId
      userId
      shopId
      quantity
      id
    }
  }`;

const DELETE_PRODUCT = gql`
mutation Mutation($delete: Boolean, $productId: Int, $userId: Int, $shopId: Int) {
    Cart(Delete: $delete, productId: $productId, userId: $userId, shopId: $shopId) {
      productId
      userId
      shopId
    }
  }
`;

const ADD_TO_CART = gql`mutation Cart($userId: Int, $productId: Int, $quantity: Int, $shopId: Int, $update: Boolean) {
    Cart(userId: $userId, productId: $productId, quantity: $quantity, shopId: $shopId, Update: $update) {
        id
        productId
        quantity
        shopId
        userId
    }
}`

const ADD_TO_WHISLIST = gql`mutation Mutation($userId: Int, $productId: Int, $shopId: Int, $delete: Boolean) {
    Wishlist(userId: $userId, productId: $productId, shopId: $shopId, Delete: $delete) {
      productId
    }
  }`


export const SingleProduct = ({ open, handleClose, product }) => {

    const [selectProduct, setSelectProduct] = useState([]);
    const [loading, setLoading] = useState(false)
    const [viewMore, setViewMore] = useState(false)

    const [openAlert, setOpenAlert] = useState(false)
    const [addToCartMutation, { loading: addToCartLoading, error: addToCartError }] = useMutation(ADD_TO_CART);
    const [updateProduct, { loading: updateProductLoading, error: updateProductError }] = useMutation(UPDATE_PRODUCT);
    const [deleteProduct, { loading: deleteProductLoading, error: deleteProductError }] = useMutation(DELETE_PRODUCT);
    const [addToLikeMutation, { loading: addToLikeLoading, error: addToLikeError }] = useMutation(ADD_TO_WHISLIST);



    const { loading: getProductsLoading, error: getProductsError, data: getProductsData, refetch: getProductsRefetch } = useQuery(GET_PRODUCTS, {
        variables: {
            "filter": {
                "productId": Number(product?.id),
                "userId": userId(),
            }
        },
    });

    const [quantity, setQuantity] = useState(null);

    useEffect(() => {
        if (getProductsRefetch) {
            getProductsRefetch()
            
        }
        if (getProductsLoading) {
            setLoading(true)
        }
        if (getProductsData) {
           console.log(getProductsData)
            setLoading(false)
            
            if (getProductsData?.products) {
                
                setQuantity(getProductsData?.products[0]?.quantity.quantity)
            }
            console.log("getProductsData.products", getProductsData.products);
            setSelectProduct(getProductsData.products)
        }
    }, [getProductsData, getProductsRefetch]);
  console.log(quantity)
    const handleDecrease = (product) => {
        let quantity = Number(product?.quantity?.quantity) - 1;
        if (quantity > 0) {
            updateProduct({
                variables: {
                    update: true,
                    productId: Number(product?.id),
                    quantity: Number(product.quantity.quantity) - 1,
                    shopId: product.shopId,
                    userId: userId(),
                },
            })
                .then((response) => {
                    if (getProductsRefetch) {
                        getProductsRefetch()
                    }
                })
                .catch((mutationError) => {
                    // Handle any errors that occurred during the mutation.
                    console.error('Mutation error:', mutationError);
                });
        } else {
            // onDelete(product)

        }
    }

    const addToCart = (product, index) => {

        if (!userId()) {
            window.location.href = "https://you.strackit.com/?redirectto=" + window.location.href;
        } else {

            setOpenAlert(false)
            if (product?.isAddedToCart?.inCart) {
                setOpenAlert(false)
                handleIncrease(product);
                return;
            } else {
                addToCartMutation({
                    variables: {
                        productId: Number(product?.id),
                        shopId: product.shopId,
                        userId: userId(),
                        quantity: quantity <= 0 ? 1 : quantity,
                    },
                })
                    .then((response) => {
                        if (getProductsRefetch) {
                            getProductsRefetch()
                        }
                        setOpenAlert(true)
                    })
                    .catch((mutationError) => {
                        // Handle any errors that occurred during the mutation.
                        console.error('Mutation error:', mutationError);
                    });
            }
        }

    };

    const handleLikeClick = (product) => {
        if (!userId()) {
            window.location.href = "https://you.strackit.com/?redirectto=" + window.location.href;
        } else {
            // setLoading(true)
            addToLikeMutation({
                variables: {
                    userId: userId(),
                    productId: Number(product.id),
                    shopId: product.shopId,
                    delete: product.wishList.like
                },
            })
                .then((response) => {
                    if (getProductsRefetch) {
                        getProductsRefetch()
                    }
                    // setLoading(false)
                })
                .catch((mutationError) => {
                    // setLoading(false)
                    console.error('Mutation error:', mutationError);
                });
        }
    }


    const handleIncrease = (product) => {
        if (quantity <= 0) {
            addToCart(product)
        } else {
            updateProduct({
                variables: {
                    update: true,
                    productId: Number(product?.id),
                    quantity: quantity <= 0 ? 1 : quantity + 1,
                    shopId: product.shopId,
                    userId: userId(),
                },
            })
                .then((response) => {
                    if (getProductsRefetch) {
                        getProductsRefetch()
                    }
                     setOpenAlert(false)
                })
                .catch((mutationError) => {
                    console.error('Mutation error:', mutationError);
                });
        }
    }

    const closeAlert = () => {
        setOpenAlert(false)
    }

    let prize;
    if (selectProduct) {
        prize = selectProduct[0]?.prize;
        if (selectProduct[0]?.discount > 0) {
            let discount = selectProduct[0]?.discount
            prize = prize - discount;
        }
        if (selectProduct[0]?.tax > 0) {
            let tax = ((prize * selectProduct[0].tax) / 100);
            prize = prize + tax;
        }
    }

    let images = []
    if (selectProduct) {
        images = [{ url: "https://s3.ap-south-1.amazonaws.com/business.strackit.com/" + selectProduct[0]?.featureImage }]
        try {
            console.log("productImage3", selectProduct[0], getProductsData.products);
            selectProduct[0]?.productImage.map((c) => {
                if (c.image) {
                    images.push({ url: "https://s3.ap-south-1.amazonaws.com/business.strackit.com/" + c.image });
                }
            });
        } catch (error) { }
        console.log('images', images, "selectProduct", selectProduct, "selectProduct[0].productImage","quantity",quantity)
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth="lg"
                open={open}
                onClose={handleClose}
            >
                <DialogActions><IoMdClose style={{ fontSize: "23px", marginRight: "1%", marginTop: "1%", cursor: "pointer" }}
                    onClick={handleClose}
                /></DialogActions>
                {loading ? <DialogContent style={{ width: "100%" }}>
                    <BabelLoading style={{ position: "relative", margin: "auto", marginTop: "7%", marginBottom: "8%" }} />
                </DialogContent>
                    :
                    <DialogContent>
                        <div className="dialog-container">
                            <div>
                                <Carousel
                                    infiniteLoop={true}
                                    showIndicators={false}
                                    showStatus={false}
                                    thumbWidth={80}
                                    showThumbs={true}
                                    swipeable={true}

                                    className="productCarousel"
                                >
                                    {images?.map((src) => (
                                        <img className="carl-img" src={src?.url} />
                                    ))}
                                    {/* <img
                                        className="carl-img"
                                        // style={{}}
                                        src={selectProduct ? selectProduct[0]?.featureImage ? "https://s3.ap-south-1.amazonaws.com/business.strackit.com/" + selectProduct[0]?.featureImage : Women : null} /> */}
                                </Carousel>
                            </div>
                            <div style={{ marginBottom: "11%" }}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <h4 className="single-prod-name">{selectProduct ? selectProduct[0]?.name : null}</h4>
                                    {selectProduct ? selectProduct[0]?.wishList.like ? <FaHeart style={{ color: "#717fe0", fontSize: "16px" }}
                                        onClick={() => {
                                            let item = selectProduct ? selectProduct[0] : null;
                                            handleLikeClick(item)
                                        }}
                                    /> :
                                        <CiHeart
                                            style={{ fontSize: "20px" }}
                                            onClick={() => {
                                                let item = selectProduct ? selectProduct[0] : null;
                                                handleLikeClick(item)
                                            }} /> : null}
                                </div>
                                <span style={{ marginRight: "1%", fontSize: "17px" }}>₹</span><span className="single-prod-prize">{selectProduct ? Math.round(prize) : null}</span>
                                {viewMore ? <p className="single-prod-description">{selectProduct ? selectProduct[0]?.description.replace(/<\/?[^>]+(>|$)/g, "") : null}</p> :
                                    <p className="single-prod-description">{selectProduct ? selectProduct[0]?.description.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 600) : null}</p>}
                                {selectProduct ? selectProduct[0]?.description.replace(/<\/?[^>]+(>|$)/g, "").length > 600 ? viewMore ?
                                    <h4
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setViewMore(false)
                                        }}
                                    >ViewLess</h4> :
                                    <h4
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setViewMore(true)
                                        }}
                                    >ViewMore</h4> : null : null}
                                <Grid>
                                    <GridColumn mobile={16} tablet={8} computer={8}>
                                        <div style={{ display: "flex", textAlign: "center", marginTop: "3%" }}>
                                            <button className="dialog-button"
                                                onClick={() => {
                                                    if (quantity || quantity > 0) {
                                                        setQuantity(quantity - 1)
                                                        handleDecrease(product)
                                                    }

                                                }}
                                            >-</button>
                                            <div  style={{ width: "32%", border: "1px solid #e6e6e6", backgroundColor: "#f7f7f7", textAlign: "center", padding: "5%" }} >
                                                {quantity}
                                            </div>
                                            <button className="dialog-button"
                                                onClick={() => {
                                                    setQuantity(quantity + 1)
                                                    handleIncrease(product)
                                                }}
                                            >+</button>
                                        </div>
                                    </GridColumn>
                                    <GridColumn mobile={16} tablet={8} computer={6}>
                                        {selectProduct ? selectProduct[0]?.isAddedToCart?.inCart ?
                                            <Link style={{ width: "100%" }} to="/cart" >
                                                <button className="auto-button" onClick={() => {
                                                    handleClose()
                                                }}> Go To Cart</button>
                                            </Link> :
                                            <button className="auto-button" onClick={() => { 
                                                addToCart(selectProduct[0]) 
                                                handleClose()
                                                }}> Add Cart</button> : null}
                                    </GridColumn>
                                </Grid>

                            </div>

                        </div>

                    </DialogContent>}
                {/* <DialogActions>
                    <Button
                        style={{ color: "green", border: "1px solid black" }}
                        onClick={() => {
                            addPhoenIn()
                        }}
                    >
                        Update
                    </Button>
                    <Button variant="outlined" style={{ color: "red", border: "1px solid black" }}
                        onClick={() => {
                            setcustomerPhoneNumber("")
                            setContactDialog(false)
                        }}
                    >
                        Close
                    </Button>
                </DialogActions> */}
            </Dialog>

            <SuccessAlert  openAlert={openAlert} closeAlert={closeAlert} title={selectProduct ? selectProduct[0]?.name : null} message="is added to cart !" />
        </React.Fragment>
    )
}