import React, { useEffect, useState, useContext } from "react";
import { gql, useMutation, useQuery } from '@apollo/client';
import "../../Assets/css/OrderProduct.css"
import { Card, Table, Grid } from "semantic-ui-react";
import GlobalContext from "../../GlobalContext"
import NoOrder from "../../Assets/Images/NoOrder.png"
import userId from "../../UserId";
import { Navbar } from "../../Navbar";
import { Footer } from "../../Footer";
import { Loader } from "../Loader";

const GET_ORDER_ITEMS_QUERY = gql`
  query OrderHistory($filter: orders) {
    orderHistory(filter: $filter) {
      shopId
      customerId
      customerName
      customerMobile
      id
      customerAddress
      voucherNo
      orderType
      customerGstin
      userOrder
      pickuptime
      rating
      feedback
      paymentInfo
      orderdetails {
        id
        masterId
        stockId
        quantity
        status
        price
        tax
        dnp
        discount
        Products {
          name
          prize
          featureImage
          discount
          addedon
          tax
        }
      }
    }
  }
`;



export default function OrderProduct() {
    const context = useContext(GlobalContext);
    const [orderProducts, setOrderProducts] = useState([])
    const [showSpinner, setShowSpinner] = useState(false)
    const [loading, setLoading] = useState(false)
    const { loading: orderProductLoading, error: orderProductError, data: orderProduct, refetch: orderProductRefetch } = useQuery(GET_ORDER_ITEMS_QUERY, {
        variables: {
            "filter": {
                "userId": userId(),
                "shopId": context.shopId
            }
        },
    });

    useEffect(() => {
        // if (orderProductLoading) {
        //     setShowSpinner(true)
        // } else if (orderProductError) {
        //     setShowSpinner(false)
        // }
        if(orderProductRefetch){
            orderProductRefetch()
        }
        setLoading(true)
        if (orderProduct) {
            setLoading(false)
            setOrderProducts(orderProduct.orderHistory.slice(0, 10))
        }
    })

    if(loading){
        return(
            <Loader />
        )
    }


    return (
        <React.Fragment>
            <Navbar />
            {/* <h2 style={{ textAlign: "center", color: "#089b7d", opacity: "3" }}>My Order</h2> */}
            <div className="order-container">
                {orderProducts.length > 0 ? <h2 style={{ textAlign: "center", color: "#089b7d", opacity: "3" }}>My Order</h2> : null}
                {/* {this.state.loading ? <Loading loading={this.state.loading} /> : null} */}
                {orderProducts.length > 0 ? <div>
                    <div className="order-item-container">

                        <Grid>
                            <Grid.Column mobile={16} table={16} computer={18}>
                                {orderProducts.map((item) => {
                                    let x = [];
                                    return (
                                        <Card fluid>
                                            <Card.Content>
                                                <Grid>
                                                    <Grid.Column mobile={8} tablet={8} computer={10}>
                                                        <h3>{`Order : #${item.id}`}</h3>
                                                        <p>{`Invoice No: ${item.voucherNo}`}</p>
                                                        <p>{`Total Items : ${item.orderdetails.length}`}</p>
                                                    </Grid.Column>
                                                    <Grid.Column mobile={8} tablet={8} computer={6}>
                                                        <b>Delivery Details</b>
                                                        <p>{item.customerName}</p>
                                                        <p>{item.customerMobile}</p>
                                                        <b>{`Address`}</b>
                                                        <p>{item.customerAddress}</p>
                                                    </Grid.Column>
                                                </Grid>
                                            </Card.Content>
                                            <Card.Content extra>
                                                {item.orderdetails.map((c) => {
                                                    let product = c.Products[0];
                                                    x.push(
                                                        <Table.Row>
                                                            <Table.Cell style={{}}>
                                                                {product?.name}
                                                            </Table.Cell>
                                                            <Table.Cell>{c.quantity}</Table.Cell>
                                                            <Table.Cell>
                                                                {((c.price - c.discount) * c.quantity +
                                                                    ((c.price - c.discount) *
                                                                        c.quantity *
                                                                        c.tax) /
                                                                    100).toFixed(2)}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {c.status == 1 || c.status == "1"
                                                                    ? "Waiting For Seller Accept"
                                                                    : c.status == 2 || c.status == "2"
                                                                        ? "Product Accepted"
                                                                        : c.status == 3 || c.status == "3"
                                                                            ? "Product Packing"
                                                                            : c.status == 4 || c.status == "4"
                                                                                ? "Product Shipping"
                                                                                : "Product Delivered"}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    );
                                                })}
                                                <Table celled style={{ width: "100%" }}>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell style={{ width: "60%" }}>
                                                                Product Name
                                                            </Table.HeaderCell>
                                                            <Table.HeaderCell style={{ width: "10%" }}>
                                                                Qty
                                                            </Table.HeaderCell>
                                                            <Table.HeaderCell style={{ width: "10%" }}>
                                                                Price
                                                            </Table.HeaderCell>
                                                            <Table.HeaderCell style={{ width: "20%" }}>
                                                                Status
                                                            </Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>{x}</Table.Body>
                                                </Table>
                                            </Card.Content>
                                        </Card>
                                    );
                                })}
                            </Grid.Column>
                        </Grid>
                    </div>
                </div> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh', margin: "auto" }}>
                    <center style={{ textAlign: 'center', }}>
                        <img style={{ height: "60%", width: "60%" }} src={NoOrder} />
                        <div style={{ fontSize: "17px", fontWeight: "600" }} >No Orders</div>
                        {userId() ? null : <div style={{ fontSize: "17px", fontWeight: "600" }} >Login To Show Orders</div>}
                    </center></div>}
            </div>
            {showSpinner && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(211,211,211,0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999
                }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
            <Footer />
        </React.Fragment>
    )
}