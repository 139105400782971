import React,{ useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from "@apollo/client";
import Client from './ApolloClient';
import { BrowserRouter } from 'react-router-dom';
import Cookies from "js-cookie";
import {Provider} from 'react-redux'
import {store} from "../src/app/store"
const RootComponent = () => {
  useEffect(() => {
    const url = new URL(window.location.href);
    const params = url.searchParams.get("value");
    const redirectto = url.searchParams.get("redirectto");
    const paramsName = url.searchParams.get("name");
    const expirationDays = 10;
    if (params) {
    
      Cookies.set(paramsName, params, { expires: expirationDays });
      window.location.href = redirectto;
    }
  }, []);

  return (
    <React.StrictMode>
      <BrowserRouter>
        <ApolloProvider client={Client}>
          <Provider store={store}>
          <App />
          </Provider>
         
        </ApolloProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<RootComponent />
);

reportWebVitals();
