

import {jwtDecode} from "jwt-decode";
import Cookies from "js-cookie";

 const userId = () => {
    try {
        const cookieValue = Cookies.get("ualum");
        if (cookieValue) {
            const decodedValue = JSON.parse(cookieValue);
            const decodedAuth = jwtDecode(decodedValue.auth);
            return parseInt(decodedAuth.user_id)
        }
        return null;
    } catch (error) {
        return null;
    }
}
export const DOMAIN = "https://t2.shop.strackit.com/"

export default userId;
