import React, { useEffect, useState } from "react";
import { CiHeart } from "react-icons/ci";
import { Button, Card, GridColumn } from "semantic-ui-react";
import { SingleProduct } from "../SingleProduct";
import { gql, useQuery, useMutation } from "@apollo/client";
import userId from "../../UserId";
import { FaHeart } from "react-icons/fa";
import NoProductImage from "../../Assets/Images/NoProductImage.png"

const ADD_TO_WHISLIST = gql`mutation Mutation($userId: Int, $productId: Int, $shopId: Int, $delete: Boolean) {
    Wishlist(userId: $userId, productId: $productId, shopId: $shopId, Delete: $delete) {
      productId
    }
  }`


export const Product = ({ products, getProductsRefetch, searchTerm, setProducts }) => {
    const [isZoomed, setIsZoomed] = useState(false);
    const [openDialog, setOpenDialog] = useState(false)
    const [product, setProduct] = useState(null)
    const [loading, setLoading] = useState(false);
    const [addToLikeMutation, { loading: addToLikeLoading, error: addToLikeError }] = useMutation(ADD_TO_WHISLIST);

    let demo = "https://preview.colorlib.com/theme/cozastore/images/product-07.jpg"

    const handleClose = () => {
        setOpenDialog(false)
        setIsZoomed(false)
    }

    const handleLikeClick = (product) => {
        if (!userId()) {
            window.location.href = "https://you.strackit.com/?redirectto=" + window.location.href;
        } else {
            setLoading(true)
            addToLikeMutation({
                variables: {
                    userId: userId(),
                    productId: Number(product.id),
                    shopId: product.shopId,
                    delete: product.wishList.like
                },
            })
                .then((response) => {
                    if (getProductsRefetch) {
                        getProductsRefetch()
                    }
                    setLoading(false)
                })
                .catch((mutationError) => {
                    setLoading(false)
                    console.error('Mutation error:', mutationError);
                });
        }
    }


    // useEffect(()=>{
    //     if(searchTerm){
    //         alert("1")
    //         setProducts(products.filter(product => product.name.toLowerCase().includes(searchTerm.toLowerCase())))
    //     }

    // },[searchTerm])

    // if (loading) {
    //     return (
    //         <Loader />
    //     )
    // }

    return (
        <React.Fragment>

            {products?.map((item) => {
                if (!item) {
                    return;
                }
                let prize = item?.prize;
                if (item?.discount > 0) {
                    let discount = item?.discount
                    prize = prize - discount;
                }
                if (item?.tax > 0) {
                    let tax = ((prize * item.tax) / 100);
                    prize = prize + tax;
                }
                return (
                    <GridColumn mobile={15} computer={4} tablet={8}>
                        <Card style={{ width: "100%", height: "24rem", margin: "0" }} >
                            <div
                                className={`image-container ${isZoomed ? 'zoomed' : ''}`}
                                onMouseEnter={() => setIsZoomed(true)}
                                onMouseLeave={() => setIsZoomed(false)}
                            >
                                <img
                                    onClick={() => {
                                        setOpenDialog(true)
                                        setProduct(item)
                                    }}
                                    style={{ width: "100%", height: "100%", cursor: "pointer" }} className="zoomable-image" src={item?.featureImage ? "https://s3.ap-south-1.amazonaws.com/business.strackit.com/" + item?.featureImage : demo}></img>
                                <div className="overlay">
                                    <button className="product-button"
                                        onClick={() => {
                                            setOpenDialog(true)
                                            setProduct(item)
                                        }}
                                    >Quick View</button>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: '2%', padding: "4%" }}>
                                <div className="product-name">{item?.name}</div>
                                <div className="product-heart" onClick={() => {
                                    handleLikeClick(item)
                                }} style={{ fontSize: "20px" }}>{item.wishList.like ? <FaHeart style={{ color: "#717fe0", fontSize: "16px" }} /> :
                                    <CiHeart />}</div>
                            </div>
                            <div style={{ fontSize: "14px", padding: "4%" }}>₹{Math.round(prize)}</div>
                        </Card>
                        </GridColumn>)
            })}
            <SingleProduct open={openDialog} handleClose={handleClose} product={product} getProductsRefetch={getProductsRefetch} />
        </React.Fragment>
    )
}