import React, { useContext, useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import GlobalContext from "../GlobalContext";
import "../Assets/css/Footer.css"
import { Button, Icon, Input, Search } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { FaLocationCrosshairs } from "react-icons/fa6";
import { FaPhoneVolume } from "react-icons/fa6";
import userId from "../UserId";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { GridColumn, Grid } from 'semantic-ui-react'

const GET_MASTER_CATEGORIES = gql`
query MasterCategories($filter: MasterCategoryInput) {
    masterCategories(filter: $filter) {
      shopId
      category
      image
      id
      position
      status
      addedon
    }
  }
`;


const ADD_CMS = gql`mutation CustomerMessage($shopId: Int!, $userId: Int!, $name: String!, $mobile: String!, $email: String!, $subject: String!, $message: String!) {
    CustomerMessage(shopId: $shopId, userId: $userId, name: $name, mobile: $mobile, email: $email, subject: $subject, message: $message) {
      id
      shopId
      name
      mobile
    }
  }
  `


const GET_CUSTOMER_SERVICE = gql`
query Shop($filter: ShopInput) {
  shop(filter: $filter) {
    address
    phone
    CMS {
      privacyPolicy
      paymentSecurity
      termsOfUse
      returnsPolicy
      termsAndConditions
      cancellationPolicy
    }
  }
}
`;

export const Footer = () => {
    const context = useContext(GlobalContext);
    const [masterCategory, setMasterCategory] = useState([]);
    const [customerService, setCustomeService] = useState()
    const [customerName, setCustomerName] = useState(null);
    const [address, setAddress] = useState(null)
    const [mobileNumber, setMobileNumber] = useState(null)
    const [mail, setMail] = useState(null)
    const [subject, setSubject] = useState(null)
    const [message, setMessage] = useState(null)
    const [openDialog, setOpenDialog] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [messageResoponse, setMessageResponcse] = useState(false);

    let category = [
        { category: "women" },
        { category: "men" },
        { category: "shoes" },
        { category: "Watches" },
    ]



    const [addCMSQuery, { loading: addCMSQueryLoading, error: addCMSQueryError }] = useMutation(ADD_CMS);

    const { loading: getMasterCategoriesLoading, error: getMasterCategoriesError, data: getMasterCategoriesData, refetch: getMasterCategoriesRefetch } = useQuery(GET_MASTER_CATEGORIES, {
        variables: {
            "filter": {
                "shopId": context.shopId,
            }
        },
    });

    const { loading: getCMSLoading, error: getCMSError, data: getCMSData, refetch: getCMSRefetch } = useQuery(GET_CUSTOMER_SERVICE, {
        variables: {
            "filter": {
                "id": context.shopId
            }
        },
    });


    const sendMessage = () => {
        if (customerName && mobileNumber && mail && subject && message) {
            addCMSQuery({
                variables: {
                    "shopId": context.shopId,
                    "userId": userId(),
                    "name": customerName,
                    "mobile": mobileNumber,
                    "email": mail,
                    "subject": subject,
                    "message": message
                },
            })
                .then((response) => {
                    setCustomerName(null)
                    setMessage(null)
                    setMobileNumber(null)
                    setSubject(null);
                    setMail(null)
                    setMessageResponcse(true)
                })
                .catch((mutationError) => {
                    console.error('Mutation error:', mutationError);
                });
        } else {
            setMessageError(true)
        }
    }

    useEffect(() => {
        if (getMasterCategoriesData) {
            setMasterCategory(getMasterCategoriesData.masterCategories)
        }
        if (getCMSData && getCMSData.shop) {
            setAddress(getCMSData?.shop[0])
            setCustomeService(getCMSData?.shop[0].CMS)
        }
    }, [getMasterCategoriesData, getCMSData])

    return (
        <div className="footer">
            <Grid  >

                <GridColumn mobile={16} tablet={5} computer={4} className="footer-categories">
                    <div style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "3%" }}>Categories</div>
                    {masterCategory?.map((item) => {
                        return (
                            <Link style={{ color: "white" }} to={`/${item.category}`}>
                                <div className="footer-contents" >{item.category}</div>
                            </Link>
                        )
                    })}
                </GridColumn>
                {customerService ? <GridColumn mobile={16} tablet={5} computer={4} className="footer-categories">

                    <div style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "3%" }}>CONSUMER POLICY</div>
                    {customerService?.cancellationPolicy ? <Link style={{ color: "white" }} to="/terms">
                        <div className="footer-contents" >Terms & contitions</div>
                    </Link> : null}
                    {customerService?.termsAndConditions ? <Link style={{ color: "white" }} to="/cancelation-refund">
                        <div className="footer-contents" >Cancellation & Refund</div>
                    </Link> : null}
                    {customerService?.privacyPolicy ? <Link style={{ color: "white" }} to="/privacy-policy">
                        <div className="footer-contents" >Privacy Policy</div>
                    </Link> : null}
                    {customerService?.paymentSecurity ? <Link style={{ color: "white" }} to="/payment">
                        <div className="footer-contents" >Payment & Security</div>
                    </Link> : null}
                </GridColumn> : null}
                <GridColumn mobile={16} tablet={5} computer={4} className="footer-categories" >
                    <div style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "3%" }}>CONTACT US</div>

                    <div className="footer-contents" >
                        <div style={{ display: "flex", gap: "3%", marginBottom: "5%" }}> <div><FaLocationCrosshairs style={{ color: "white", fontSize: "20px", marginRight: "4%" }} /></div>
                            <div> {address?.address}</div></div>
                        <div style={{ display: "flex", gap: "3%" }}> <div><FaPhoneVolume style={{ color: "white", fontSize: "20px", marginRight: "4%" }} /></div>
                            <div> {address?.phone}</div></div>
                    </div>
                </GridColumn>
                <GridColumn mobile={16} tablet={5} computer={4} className="footer-categories">
                    <div style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "3%" }}>Customer Enquiry</div>
                    <div>
                        <button className="footer-button"
                            onClick={() => {
                                setOpenDialog(true)
                            }}
                        > SEND ENQUIRY</button>
                    </div>
                </GridColumn>

            </Grid>
            <Dialog
                open={openDialog}
                id="add-enq"

                onClose={() => {
                    setOpenDialog(false)
                }}
            // PaperComponent={PaperComponent}
            // aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ textAlign: "end" }}>
                    <div style={{ display: "flex" }}>
                        <div>CUSTOMER ENQUIRY</div>
                        <IoMdClose style={{ fontSize: "23px", marginLeft: "auto", marginTop: "1%", cursor: "pointer" }}
                            onClick={() => {
                                setOpenDialog(false)
                            }}
                        />
                    </div>


                </DialogTitle>
                <DialogContent>
                    <div className="footer-categories">
                        <div>
                            <Input placeholder="Name"
                                value={customerName}
                                onChange={(e) => {
                                    setCustomerName(e.target.value);
                                }}
                            // className="footer-input"
                            />
                        </div>
                        <div>
                            <Input placeholder="Mobile"
                                value={mobileNumber}
                                onChange={(e) => {
                                    setMobileNumber(e.target.value);
                                }}
                            />
                        </div>
                        <div>
                            <Input placeholder="email@example.com"
                                value={mail}
                                onChange={(e) => {
                                    setMail(e.target.value);
                                }}
                            />
                        </div>
                        <div>
                            <Input placeholder="subject"
                                value={subject}
                                onChange={(e) => {
                                    setSubject(e.target.value);
                                }}
                            />
                        </div>
                        <div>
                            <Input placeholder="Message"
                                value={message}
                                onChange={(e) => {
                                    setMessage(e.target.value);
                                }}
                            />
                        </div>
                        <div>

                        </div>
                    </div>


                </DialogContent>
                <DialogActions>
                    <Button
                        // className="footer-button"
                        style={{ backgroundColor: "#717fe0" }}
                        onClick={() => {
                            sendMessage()
                            setOpenDialog(false)
                        }}
                    > SEND</Button>
                </DialogActions>
            </Dialog >
            <Dialog
                open={messageResoponse}
                onClose={() => { setMessageResponcse(false) }}
                // onClose={handleClose}
                style={{ width: "40%", margin: "auto" }}
            >
                <DialogContent>
                    <div class="swal-icon swal-icon--success">
                        <span class="swal-icon--success__line swal-icon--success__line--long"></span>
                        <span class="swal-icon--success__line swal-icon--success__line--tip"></span>

                        <div class="swal-icon--success__ring"></div>
                        <div class="swal-icon--success__hide-corners"></div>
                    </div>
                    <div className="alert-success" >Message Send Successfully</div>
                </DialogContent>
                <DialogActions>
                    <Button style={{ backgroundColor: "#7cd1f9", color: "white", cursor: "pointer" }}
                        onClick={() => { setMessageResponcse(false) }}
                    >Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={messageError}
                onClose={() => { setMessageError(false) }}
                // onClose={handleClose}
                style={{ width: "40%", margin: "auto" }}
            >
                <DialogContent>
                    <div className="alert-success" >Fill The All Text Filed</div>
                </DialogContent>
                <DialogActions>
                    <Button style={{ backgroundColor: "#7cd1f9", color: "white", cursor: "pointer" }}
                        onClick={() => {
                            setMessageError(false)
                            setOpenDialog(true)
                        }}
                    >Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}