import logo from './logo.svg';
import React, { useEffect, useState } from "react"
import './App.css';
import { Navbar } from './Navbar';
import GlobalContext from "./GlobalContext";
import { Home } from './Components/Home';
import { Routes, Route } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import TermsAndConditions from './Footer/TermsAndCondition';
import PrivactPolicy from './Footer/PrivacyPolicy';
import CancellationAndRefund from './Footer/CancellationAndRefund';
import { SecondaryCategory } from './Components/SecondaryCategory';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Cart } from './Components/Cart';
import { Whislist } from './Components/WhisList';
import OrderProduct from './Components/MyOrder';
import ReactGA from 'react-ga';
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { gql, useQuery, useMutation } from "@apollo/client";
import PaymentSecurity from './Footer/PaymentSecurity';

const GET_CUSTOMER_SERVICE = gql`
query Shop($filter: ShopInput) {
  shop(filter: $filter) {
    CMS {
      privacyPolicy
      paymentSecurity
      termsOfUse
      returnsPolicy
      termsAndConditions
      cancellationPolicy
    }
  }
}
`;

const GET_SHOP_ID = gql`query Shop($filter: ShopInput) {
  shop(filter: $filter) {
    name
    id
  }
}`;


function App() {
  const [userId, setUserId] = useState(null);
  const [decodedCookie, setDecodedCookie] = useState(null);
  const [redirected, setRedirected] = useState(false);
  const [customerService, setCustomeService] = useState(null);
  const currentUrl = new URL(window.location.href);
  const parsedUrl = currentUrl.origin
  const customerShopId = currentUrl.searchParams.get('shop_id');
  const [shopId, setShopId] = useState(null);


  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    const fetchData = async () => {
      try {
        const cookieValue = await Cookies.get("ualum");
        if (cookieValue) {
     
          const decodedValue = await JSON.parse(cookieValue);
          await setDecodedCookie(decodedValue);
          const decodedAuth = await jwtDecode(decodedValue.auth);
          setUserId(await parseInt(decodedAuth.user_id));
          setShopId(318)
          await localStorage.setItem("redirected", "true");
        }
        else if (localStorage.getItem("redirected")) {
          localStorage.removeItem("redirected")
          setRedirected(true);
        }
      } catch (error) {
        console.error("Error decoding cookie:", error);
      }
    };

    fetchData();
  }, []);




  const { loading: getCMSLoading, error: getCMSError, data: getCMSData, refetch: getCMSRefetch } = useQuery(GET_CUSTOMER_SERVICE, {
    variables: {
      "filter": {
        "id": 318
      }
    },
  });
console.log(shopId)
  const { loading: getShopIdLoading, error: getShopIdError, data: getShopIdData, refetch: refetchGetShopIdData } = useQuery(GET_SHOP_ID, {
    variables: {
      "filter": {
        "customDomain": parsedUrl
      }
    },
  });

  useEffect(() => {
    if (customerShopId) {
      setShopId(Number(customerShopId));
    } else {
      if (getShopIdData) {
        const newShopId = Number(getShopIdData.shop[0].id);
        setShopId(newShopId);
      }
    }
  }, [getShopIdData, currentUrl]);

  useEffect(() => {
    if (getCMSData && shopId) {
      setCustomeService(getCMSData.shop[0].CMS)
     
    }
  }, [getCMSData, shopId])

  return (
    <div className='App' >
      <GlobalContext.Provider
        value={{ shopId }}
      >
        {/* <Navbar /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:productName/*" element={<SecondaryCategory navbar={true} />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/whisList" element={<Whislist />} />
          <Route path="/myorders" element={<OrderProduct />} />
          <Route path="/terms" element={<TermsAndConditions customerService={customerService} />} />
          <Route path="/privacy-policy" element={<PrivactPolicy customerService={customerService} />} />
          <Route path="/payment" element={<PaymentSecurity customerService={customerService} />} />
          <Route path="/cancelation-refund" element={<CancellationAndRefund customerService={customerService} />} />
          {/* <Route path="/Product/:productName/*" element={<Products  />} /> */}
        </Routes>
        {/* <Footer /> */}
      </GlobalContext.Provider>

    </div>
  );
}

export default App;
