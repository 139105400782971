import {
    ModalHeader,
    ModalContent,
    ModalActions,
    Icon,
    Modal
} from 'semantic-ui-react'
import React from "react";
import { Button } from "semantic-ui-react";
import "../../Assets/css/SuccessAlert.css"

export const SuccessAlert = ({ openAlert, closeAlert, title, message }) => {
    return (
        <Modal
            size="small"
            open={openAlert}
            onClose={closeAlert}
            style={{ top: '35%', width: "50vh", transform: 'translateY(-40%)', maxHeight: '45vh', left: "50%", transform: 'translatex(-50%)' }}

        >
            <ModalContent>
                <div class="swal-icon swal-icon--success">
                    <span class="swal-icon--success__line swal-icon--success__line--long"></span>
                    <span class="swal-icon--success__line swal-icon--success__line--tip"></span>

                    <div class="swal-icon--success__ring"></div>
                    <div class="swal-icon--success__hide-corners"></div>
                </div>
                <div className="alert-title" >{title}</div>
                <div className="alert-success" >{message ? message : null}</div>

            </ModalContent>
            <ModalActions>
                <Button style={{ backgroundColor: "#7cd1f9", color: "white", cursor: "pointer" }}
                    onClick={closeAlert}
                >Ok</Button>
            </ModalActions>
        </Modal>
    )
}