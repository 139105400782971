import React, { useContext, useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import userId from "../../UserId";
import GlobalContext from "../../GlobalContext";
import WishlistImg from '../../Assets/Images/wishlist.png'
import "../../Assets/css/Cart.css"
import {
  Table, Input, Button, Grid, GridColumn, ModalHeader,
  ModalContent,
  ModalActions,
  Icon,
  Modal
} from 'semantic-ui-react'
import { Navbar } from "../../Navbar";
import { Footer } from "../../Footer";
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { SuccessAlert } from "../SingleProduct/SuccessAlert";
import { Loader } from "../Loader"

const GET_WISHLIST_PRODUCTS = gql`
query Wishlist($filter: WishlistFilterInput) {
    wishlist(filter: $filter) {
      featureImage
      title
      prize
      tax
      discount
      productId
      id
      shopId
    }
  }
`;

const REMOVE_WISHLIST = gql`mutation Mutation($userId: Int, $productId: Int, $shopId: Int, $delete: Boolean) {
    Wishlist(userId: $userId, productId: $productId, shopId: $shopId, Delete: $delete) {
      id
      userId
      productId
      shopId
    }
  }
  `

const UPDATE_PRODUCT = gql`
mutation Mutation($productId: Int, $shopId: Int, $userId: Int, $update: Boolean, $quantity: Int) {
  Cart(productId: $productId, shopId: $shopId, userId: $userId, Update: $update, quantity: $quantity) {
    productId
    userId
    shopId
    quantity
    id
  }
}`;

const DELETE_PRODUCT = gql`
mutation Mutation($delete: Boolean, $productId: Int, $userId: Int, $shopId: Int) {
    Cart(Delete: $delete, productId: $productId, userId: $userId, shopId: $shopId) {
      productId
      userId
      shopId
    }
  }
`;

const ADD_TO_CART = gql`mutation Cart($userId: Int, $productId: Int, $quantity: Int, $shopId: Int, $update: Boolean) {
  Cart(userId: $userId, productId: $productId, quantity: $quantity, shopId: $shopId, Update: $update) {
      id
      productId
      quantity
      shopId
      userId
  }
}`



export const Whislist = () => {
  const context = useContext(GlobalContext);
  const [whisList, setWhishList] = useState([]);
  const [cartItem, setCartItem] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null)
  const [removeWishProductQuery, { loading: removeWishProductressQueryLoading, error: removeWishProductressQueryError }] = useMutation(REMOVE_WISHLIST);
  const [updateProduct, { loading: updateProductLoading, error: updateProductError }] = useMutation(UPDATE_PRODUCT);
  const [deleteProduct, { loading: deleteProductLoading, error: deleteProductError }] = useMutation(DELETE_PRODUCT);
  const [addToCartMutation, { loading: addToCartLoading, error: addToCartError }] = useMutation(ADD_TO_CART);
  const { loading: getWishListProductLoading, error: getWishListProductError, data: getWishListProductData, refetch: getWishListProductRefetch } = useQuery(GET_WISHLIST_PRODUCTS, {
    variables: {
      "filter": {
        "shopId": context.shopId,
        "userId": userId()
      }
    },
  });

  useEffect(() => {
    if (getWishListProductRefetch) {
      getWishListProductRefetch()
    }
    if (getWishListProductLoading) {
      setLoading(true)
    }
    if (getWishListProductData) {
      setLoading(false)
      setWhishList(getWishListProductData.wishlist)
      console.log("****getWishListProductData", getWishListProductData);
    }
  }, [getWishListProductData, getWishListProductRefetch]);

  const handleDecrease = (product) => {
    let quantity = Number(product.quantity) - 1;
    if (quantity > 0) {
      updateProduct({
        variables: {
          "productId": Number(product.productId),
          "shopId": context.shopId,
          "userId": userId(),
          "update": true,
          "quantity": quantity,
        },
      })
        .then((response) => {
          // if (getCartProductRefetch) {
          //   getCartProductRefetch()
          // }
        })
        .catch((mutationError) => {
          console.error('Mutation error:', mutationError);
        });
    } else {
      onDelete(product)

    }
  }

  const closeAlert = () => {
    setOpenAlert(false)
  }


  const addToCart = (product, index) => {
    if (!userId()) {
      window.location.href = "https://you.strackit.com/?redirectto=" + window.location.href;
    }

    else {
      setLoading(true)
      addToCartMutation({
        variables: {
          productId: Number(product?.productId),
          shopId: product.shopId,
          userId: userId(),
          quantity: 1,
        },
      })
        .then((response) => {
          setLoading(false)
          setCartItem(product?.title);
          setOpenAlert(true)
        })
        .catch((mutationError) => {
          setLoading(false)
          setCartItem(product?.title);
          setOpenDialog(true);
          console.error('Mutation error:', mutationError);
        });
    }
  }

  function onDelete(item) {
    // setShowSpinner(true);
    // setTimeout(() => {
    //   setShowSpinner(false);
    deleteProduct({
      variables: {
        delete: true,
        productId: Number(item.productId),
        shopId: item.shopId,
        userId: userId(),
      },
    })
      .then((response) => {
        // if (getCartProductRefetch) {
        //   getCartProductRefetch()
        // }
      })
      .catch((mutationError) => {
        // Handle any errors that occurred during the mutation.
        console.error('Mutation error:', mutationError);
      });
    // }, 1000)
  }

  const handleIncrease = (product) => {
    let quantity = Number(product.quantity) + 1;
    updateProduct({
      variables: {
        "productId": Number(product.productId),
        "shopId": context.shopId,
        "userId": userId(),
        "update": true,
        "quantity": quantity,
      },
    })
      .then((response) => {
        // if (getCartProductRefetch) {
        //   getCartProductRefetch()
        // }
      })
      .catch((mutationError) => {
        console.error('Mutation error:', mutationError);
      });
    if (updateProductError) {
      console.log("*updateProductError", updateProductError);
    }
  }


  const updateAddAddressIn = (product) => {
    setLoading(true)
    removeWishProductQuery({
      variables: {
        "userId": userId(),
        "productId": Number(product.productId),
        "shopId": context.shopId,
        "delete": true
      },
    })
      .then((response) => {
        setLoading(false)
        if (getWishListProductRefetch) {
          getWishListProductRefetch()
        }
      })
      .catch((mutationError) => {
        setLoading(false)
        console.error('Mutation error:', mutationError);
      });
  }

  if (loading) {
    return (
      <Loader />
    )
  }

  return (
    <React.Fragment>
      <Navbar />
      <div id="wishlist-table-container" >
        {whisList?.length > 0 ? <Table attached='top' basic id="cart-table-container" >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ paddingLeft: "3.5%" }}>
                <div>PRODUCT</div></Table.HeaderCell>
              <Table.HeaderCell>PRICE</Table.HeaderCell>
              <Table.HeaderCell>CART</Table.HeaderCell>
              <Table.HeaderCell>ACTION</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {whisList?.map((item) => {
              if (!item) {
                return;
              }
              let prize = item?.prize;
              if (item?.discount > 0) {
                let discount = item?.discount
                prize = prize - discount;
              }
              if (item?.tax > 0) {
                let tax = ((prize * item.tax) / 100);
                prize = prize + tax;
              }
              return (
                <Table.Row>
                  <Table.Cell>
                    <Grid id="cart-name-image">
                      <GridColumn mobile={12} tablet={8} computer={5}>
                        <img id="wishlist-product-image" src={item?.featureImage ? "https://s3.ap-south-1.amazonaws.com/business.strackit.com/" + item?.featureImage : null} style={{objectFit:"cover"}} />
                      </GridColumn>
                      <GridColumn mobile={12} tablet={8} computer={5} id="cart-prod-name">{item?.title}</GridColumn>
                    </Grid>
                  </Table.Cell>
                  <Table.Cell style={{ verticalAlign: "middle" }}>₹{Math.round(prize)}</Table.Cell>
                  <Table.Cell style={{ verticalAlign: "middle" }}>
                    <button className="cart-total-button"
                      onClick={() => { addToCart(item) }}
                      style={{ marginLeft: "0%" }}> Add To Cart </button>
                  </Table.Cell>
                  <Table.Cell style={{ verticalAlign: "middle" }}>
                    <button className="cart-total-button"
                      onClick={() => {
                        setAlertMessage(true)
                        setDeleteItem(item)
                      }}
                      style={{ marginLeft: "0%" }}> Remove </button>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table> : <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
          <img style={{ height: "60%", width: "60%" }}src={WishlistImg} alt="WishList  Empty" />
          <p style={{fontSize:"17px",fontWeight:"bold"}}> WishList  Empty</p>
        </div>}
      </div>
      <Modal
        size="small"
        open={openDialog}
        onClose={() => { setOpenDialog(false) }}
        style={{ top: '30%', transform: 'translateY(-50%)', maxHeight: '25vh', width: "70npvh", left: "50%", transform: 'translatex(-50%)' }}
        centered
      >
        <ModalHeader style={{ textAlign: "end" }}>
          <div style={{ display: "flex" }}>
            <div>Message</div>
            <IoMdClose style={{ fontSize: "23px", marginLeft: "auto", marginTop: "1%", cursor: "pointer" }}
              onClick={() => {
                setOpenDialog(false)
              }}

            /> </div></ModalHeader>
        <ModalContent>
          Already {cartItem} is  added to cart.
        </ModalContent>

      </Modal>
      <Dialog
        open={alertMessage}
        onClose={() => { setAlertMessage(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>Are you sure you want to delete your Item?</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus
            style={{ backgroundColor: "#717fe0", color: "white", marginRight: "3%" }}
            onClick={() => {
              setAlertMessage(false)
              updateAddAddressIn(deleteItem)
            }}
          >
            Delete
          </Button>
          <Button
            style={{ backgroundColor: "#717fe0", color: "white" }}
            onClick={() => {
              setAlertMessage(false)
              setDeleteItem(null)
            }}
            autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessAlert openAlert={openAlert} closeAlert={closeAlert} title={cartItem} message="is added to cart !" />
      <Footer />
    </React.Fragment>
  )
}