import React, { useContext, useState, useEffect, useRef } from "react";
import { gql, useQuery } from "@apollo/client";
import GlobalContext from "../../GlobalContext";
import Women from "../../Assets/Images/Women2.jpeg"
import "../../Assets/css/MasterCategory.css"
import { Link } from "react-router-dom";
import Demo from "../../Assets/Images/DemoImage.webp";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";

const GET_MASTER_CATEGORIES = gql`
query MasterCategories($filter: MasterCategoryInput) {
    masterCategories(filter: $filter) {
      shopId
      category
      image
      id
      position
      status
      addedon
    }
  }
`;

export const MasterProduct = () => {
  let demo = "https://cdn.pixabay.com/photo/2021/10/11/23/49/app-6702045_640.png"
  const context = useContext(GlobalContext);
  const [masterCategory, setMasterCategory] = useState([])
  const { loading: getMasterCategoriesLoading, error: getMasterCategoriesError, data: getMasterCategoriesData, refetch: getMasterCategoriesRefetch } = useQuery(GET_MASTER_CATEGORIES, {
    variables: {
      "filter": {
        "shopId": context.shopId,
      }
    },
  });

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (getMasterCategoriesData && getMasterCategoriesData.masterCategories && context.shopId) {
      setMasterCategory(getMasterCategoriesData.masterCategories)
    }
  }, [getMasterCategoriesData, context.shopId])


  const handleScroll = (direction) => {
    const scrollAmount = direction === 'left' ? -100 : 100;
    scrollContainerRef.current.scrollLeft += scrollAmount;
  };

  return (
    <div style={masterCategory?.length > 3 ? { display: "flex", alignItems: "center" } : null}>
      {masterCategory?.length > 3 ? <FaChevronLeft className="secondary-arrow" onClick={() => handleScroll("left")} /> : null}
      <div className={masterCategory?.length > 3 ? "master-container-scroll" : "master-container"} ref={scrollContainerRef} >
        {Array.isArray(masterCategory) && masterCategory.map((item) => {
          return (
            <Link to={`/${item.category}`} style={{ width: "100%" }} onClick={() => {
            }} >
              <div className="mastercat-card" >
                <div className={item?.image ? "master-category-name" : "master-category-name1"} >{item.category}</div>
                {/* <div className="shop-button">Shop Now</div> */}
                {item?.image ? <img style={{ width: "100%", height: "100%" ,objectFit:"cover"}} src={"https://s3.ap-south-1.amazonaws.com/business.strackit.com/" + item.image} /> :
                  <div style={{ height: "15rem", width: "20rem" }}></div>}
                <div class="image-overlay"></div>
              </div>
            </Link>
          )
        })}

      </div>
      {masterCategory?.length > 3 ? <FaChevronRight className="secondary-right-arrow" onClick={() => handleScroll("right")} /> : null}
    </div>
  )
}