import React, { useContext, useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import userId from "../../UserId";
import GlobalContext from "../../GlobalContext";
import "../../Assets/css/Cart.css"
import { Table, Input, Grid } from 'semantic-ui-react'
import PaymentComponent from "./RazorePay";
import { Navbar } from "../../Navbar";
import { Footer } from "../../Footer";
import { Loader } from "../Loader";
import NoOrder from "../../Assets/Images/cart.png"
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Button } from "@mui/material";
import SuccessAlert from "../SingleProduct/SuccessAlert";
import { RiDeleteBinFill } from "react-icons/ri";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import {
  GridColumn, ModalHeader,
  ModalContent,
  ModalActions,
  Icon,
  Modal
} from 'semantic-ui-react'

const GET_CART_PRODUCTS = gql`
query Cart($filter: CartFilter1) {
  cart(filter: $filter) {
    productId
    quantity
    name
    shopId
    prize
    Discount
    featureImage
    userId
    description
    mastercategory
    tax
    id
  }
}
`;

const GET_CUSTOMER_ADDRESS = gql`
query Address($filter: addressFilter) {
  address(filter: $filter) {
    country
    state
    city
    street
    pincode
    phone
    name
    id
    type
  }
}
`;

const GET_COUPON = gql`
query CouponCode($filter: couponFilter) {
  couponCode(filter: $filter) {
    code
    priceRange
    description
    discount
    id
    shopId
    status
    userId
    validityFrom
    validityTo
  }
}`

const UPDATE_PRODUCT = gql`
mutation Mutation($productId: Int, $shopId: Int, $userId: Int, $update: Boolean, $quantity: Int) {
  Cart(productId: $productId, shopId: $shopId, userId: $userId, Update: $update, quantity: $quantity) {
    productId
    userId
    shopId
    quantity
    id
  }
}`;

const DELETE_PRODUCT = gql`
mutation Mutation($delete: Boolean, $productId: Int, $userId: Int, $shopId: Int) {
    Cart(Delete: $delete, productId: $productId, userId: $userId, shopId: $shopId) {
      productId
      userId
      shopId
    }
  }
`;

const ADD_ADDRESS = gql`mutation Address($userId: Int, $country: String, $state: String, $city: String, $street: String, $pincode: String, $phone: String, $name: String, $type: String) {
  Address(userId: $userId, country: $country, state: $state, city: $city, street: $street, pincode: $pincode, phone: $phone, name: $name, type: $type) {
    country
    state
    city
    street
    pincode
    phone
    name
    type
    id
  }
}
`

const UPDATE_ADDRESS = gql`mutation Address($userId: Int, $country: String, $state: String, $city: String, $street: String, $pincode: String, $phone: String, $name: String, $type: String, $addressId: Int, $update: Boolean) {
  Address(userId: $userId, country: $country, state: $state, city: $city, street: $street, pincode: $pincode, phone: $phone, name: $name, type: $type, id: $addressId, Update: $update) {
    country
    state
    city
    street
    pincode
    phone
    name
    type
    id
  }
}
`

const GET_SITE_CONFIQURATION = gql`
query Shop($filter: ShopInput) {
  shop(filter: $filter) {
    siteConfigurations {
      razorpayKey
      razorpaySecretKey
    }
    phone
    name
  }
}
  `;

const GET_SHIPPING_COST = gql`
  query ExampleQuery($filter: shippingFilter) {
    shippingCost(filter: $filter) {
      id
      price
      purchaseRange
      shopId
    }
  }
  `;

export const Cart = () => {

  const context = useContext(GlobalContext);
  const [user, setUserId] = useState();
  const[expiredCoupon,setExpiredCoupon]=useState(false)
  const [cartProduct, setCartproduct] = useState([])
  const [customerAddress, setCustomerAddress] = useState([]);
  const [customerAllAddress, setCustomerAllAddress] = useState([])
  const [updateAddQuery, { loading: updateAddressQueryLoading, error: updateAddressQueryError }] = useMutation(UPDATE_ADDRESS);
  const [updateProduct, { loading: updateProductLoading, error: updateProductError }] = useMutation(UPDATE_PRODUCT);
  const [deleteProduct, { loading: deleteProductLoading, error: deleteProductError }] = useMutation(DELETE_PRODUCT);
  const [addAddressQuery, { loading: addAddressQueryLoading, error: addAddressQueryError }] = useMutation(ADD_ADDRESS);
  const [siteConfig, setSiteConfig] = useState(null);
  const [addAddress, setAddAddress] = useState(false)
  const [loading, setLoading] = useState(false)
  const [shippingCost, setShippingCost] = useState(null);
  const [couponDetails, setCouponDetails] = useState([]);
  const [couponPrice, setCouponPrice] = useState(0);
  const [couponCode, setCouponCode] = useState(null);
  const [couponDiscount, setCouponDiscount] = useState(null);
  const [couponId, setCouponId] = useState(null);
  const [couponRange,setCouponRange] = useState(null)
  const [checkout,setCheckout]=useState(false)

  let uId = userId();

  const { loading: getCartProductLoading, error: getCartProductError, data: getCartProductData, refetch: getCartProductRefetch } = useQuery(GET_CART_PRODUCTS, {
    variables: {
      "filter": {
        "shopId": context.shopId,
        "userId": uId
      }
    },
  });


  const { loading: getCouponLoading, error: getCouponError, data: getCouponData, refetch: getCouponRefetch } = useQuery(GET_COUPON, {
    variables: {
      "filter": {
        "shopId": context.shopId,
      }
    },
  });



  const { loading: getRazorIdLoading, error: getRazorIdError, data: getRazorIdData, refetch: getRazorIdRefetch } = useQuery(GET_SITE_CONFIQURATION, {
    variables: {
      "filter": {
        "id": context.shopId,
      }
    },
  });

  const { loading: getCustomerAddressLoading, error: getCustomerAddressError, data: getCustomerAddressData, refetch: getCustomerAddressRefetch } = useQuery(GET_CUSTOMER_ADDRESS, {
    variables: {
      "filter": {
        "userId": uId
      }
    },
  });

  const { loading: getShippingLoading, error: getShippingError, data: getShippingData, refetch: getShippingRefetch } = useQuery(GET_SHIPPING_COST, {
    variables: {
      "filter": {
        "shopId": context.shopId,
      }
    },
  });

  useEffect(() => {
    if (getRazorIdData && getRazorIdData.shop) {
      setSiteConfig(getRazorIdData.shop[0])
    }
    if (context?.shopId) {
      if (getShippingData) {
        setShippingCost(getShippingData.shippingCost[getShippingData.shippingCost.length - 1])
      }
    }

  }, [getRazorIdData, getShippingData])
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // You can use 'auto' instead of 'smooth' for instant scrolling
    });
  }, [])

  const getAddressUser = async () => {
    let user = await userId();
    setUserId(user)
  }

  useEffect(() => {
    if (context?.shopId) {
      getAddressUser();
      if (getCartProductLoading) {
        setLoading(true);
      }
      if (getCartProductRefetch) {
        getCartProductRefetch()
      }
      if (getCustomerAddressRefetch) {
        getCustomerAddressRefetch()
      }
      if (getCustomerAddressData) {
        setCustomerAllAddress(getCustomerAddressData?.address);
        setCustomerName(getCustomerAddressData?.address[getCustomerAddressData?.address.length - 1]?.name)
        setcustomerPhoneNumber(getCustomerAddressData?.address[getCustomerAddressData?.address.length - 1]?.phone)
        setCustomerCountry(getCustomerAddressData?.address[getCustomerAddressData?.address.length - 1]?.country)
        setCustomerState(getCustomerAddressData?.address[getCustomerAddressData?.address.length - 1]?.state)
        setCustomerStreet(getCustomerAddressData?.address[getCustomerAddressData?.address.length - 1]?.street)
        setCustomerPincode(getCustomerAddressData?.address[getCustomerAddressData?.address.length - 1]?.pincode)
        setCustomerCity(getCustomerAddressData?.address[getCustomerAddressData?.address.length - 1]?.city)
        setCustomerId(getCustomerAddressData?.address[getCustomerAddressData?.address.length - 1]?.id)
        setCustomerAddress(getCustomerAddressData?.address[getCustomerAddressData?.address.length - 1]);

      }
      if (getCartProductData) {
        setLoading(false)
        setCartproduct(getCartProductData.cart)
      }
      if (getCouponData) {
      
        setCouponDetails(getCouponData.couponCode)
        
      }
    }
  }, [getCartProductData, getCartProductRefetch, getCouponData, getCustomerAddressData, getCustomerAddressRefetch, context?.shopId]);
  useEffect(()=>{
    const date=couponDetails.map(cou=>cou.validityTo)
    const couponDate=new Date(date)
    const currDate=new Date()
    if(currDate>couponDate){
      setExpiredCoupon(true)
    }
      
  },[couponDetails])
  const [customerName, setCustomerName] = useState('')
  const [customerPhoneNumber, setcustomerPhoneNumber] = useState('')
  const [customerCountry, setCustomerCountry] = useState('')
  const [customerState, setCustomerState] = useState('')
  const [customerStreet, setCustomerStreet] = useState('')
  const [customerPincode, setCustomerPincode] = useState('')
  const [customerCity, setCustomerCity] = useState('')
  const [addressDialog, setAddressDialog] = useState(false);
  const [customerId, setCustomerId] = useState(null)
  const [alertMessage, setAlertMessage] = useState(false)
  const [deleteItem, setDeleteItem] = useState(null);
  const errors={
    Name:false,
    Mobile:false,
    Street:false,
    City:false,
    State:false,
    country:false,
    Pincode:false
  }
const[err,setErr]=useState(errors)
const handleValidation=(e)=>{
    const checkerr=errors
    if(customerName==''){
      checkerr.Name=true
    }
    if(customerPhoneNumber==''||customerPhoneNumber.length>10||customerPhoneNumber.length<10){
      checkerr.Mobile=true
    
    }
    if(customerCountry==''){
      checkerr.country=true
    
    }
    if(customerState==''){
      checkerr.State=true
   
    }
    if(customerPincode==''||customerPincode.length<6||customerPincode.length>6){
      checkerr.Pincode=true
     
    }
    if(customerCity==''){
      checkerr.City=true
   
    }
    if(customerStreet==''){
      checkerr.Street=true
   
    }
    setErr({...checkerr})
  if(customerName&&customerPhoneNumber&&customerPhoneNumber.length==10&&customerCountry&&customerState&&customerPincode&&customerPincode.length==6&&customerCity&&customerStreet) {
    if(e.target.innerHTML=="ADD ADDRESS"){
      addAddressIn()
    }
   else{
    updateAddAddressIn()
  }

  }

    
}
  const total =
    cartProduct.reduce((acc, item) => {
      let prize = item?.prize * item?.quantity;
      if (item?.Discount > 0) {
        
        let discount = item?.Discount * item?.quantity;
        prize = prize - discount;
   
      }
      if (item?.tax > 0) {
        let tax = ((prize * item.tax) / 100);
        prize = prize + tax;
      }
      if (item && item.prize) {
        return acc + prize;
      }
      return acc;
    }, 0);


  const handleDecrease = (product) => {
    let quantity = Number(product.quantity) - 1;
    if (quantity > 0) {
      updateProduct({
        variables: {
          "productId": Number(product.productId),
          "shopId": context.shopId,
          "userId": userId(),
          "update": true,
          "quantity": quantity,
        },
      })
        .then((response) => {
          if (getCartProductRefetch) {
            getCartProductRefetch()
          }
        })
        .catch((mutationError) => {
          console.error('Mutation error:', mutationError);
        });
    } else {
      onDelete(product)

    }
  }

  const updateAddAddressIn = () => {
   
    updateAddQuery({
      variables: {
        "userId": userId(),
        "country": customerCountry,
        "state": customerState,
        "city": customerCity,
        "street": customerStreet,
        "pincode": customerPincode,
        "phone": customerPhoneNumber,
        "name": customerName,
        "type": "coza_store",
        "addressId": customerId,
        "update": true
      },
    })
      .then((response) => {
        
        if (getCustomerAddressRefetch) {
          getCustomerAddressRefetch()
          alert('Address Updated Successfully')
        }
      })
      .catch((mutationError) => {
        console.error('Mutation error:', mutationError);
      });
  }



  const addAddressIn = () => {
    addAddressQuery({
      variables: {
        "userId": userId(),
        "country": customerCountry,
        "state": customerState,
        "city": customerCity,
        "street": customerStreet,
        "pincode": customerPincode,
        "phone": customerPhoneNumber,
        "name": customerName,
        "type": "shop"
      },
    })
      .then((response) => {
        if (getCustomerAddressRefetch) {
          getCustomerAddressRefetch()
          alert('Address Added Successfully')
          setCheckout(false)
        }
      })
      .catch((mutationError) => {
        console.error('Mutation error:', mutationError);
      });
  }


  const handleIncrease = (product) => {
    let quantity = Number(product.quantity) + 1;
    updateProduct({
      variables: {
        "productId": Number(product.productId),
        "shopId": context.shopId,
        "userId": userId(),
        "update": true,
        "quantity": quantity,
      },
    })
      .then((response) => {
        if (getCartProductRefetch) {
          getCartProductRefetch()
        }
      })
      .catch((mutationError) => {
        console.error('Mutation error:', mutationError);
      });
    if (updateProductError) {
      console.log("*updateProductError", updateProductError);
    }
  }



  const cartHeader = {
    fontFamily: "Poppins-Bold",
    fontSize: "13px",
    color: "#555",
    textTransform: "uppercase",
    lineHeight: "1.6",
    paddingTop: "15px",
    paddingBottom: "15px",
  }

  function onDelete(item) {
    // setShowSpinner(true);
    // setTimeout(() => {
    //   setShowSpinner(false);
    deleteProduct({
      variables: {
        delete: true,
        productId: Number(item.productId),
        shopId: item.shopId,
        userId: userId(),
      },
    })
      .then((response) => {
        if (getCartProductRefetch) {
          getCartProductRefetch()
        }
      })
      .catch((mutationError) => {
        // Handle any errors that occurred during the mutation.
        console.error('Mutation error:', mutationError);
      });
    // }, 1000)
  }



  const checkOffer = (totalAmount, couponDiscount, shippingCost) => {
    let amount;
    let offer;
    if (couponRange) {
      if (couponRange > Math.round(totalAmount)) {
        setCouponDiscount(null)
        setCouponId(null)
        setCouponRange(null)
      }
    }
    //  console.log(typeof totalAmount,totalAmount ,typeof couponDiscount,typeof shippingCost,typeof cartAmount, typeof taxAmount);
    if (couponDiscount) {
       
      offer = couponDiscount / 100;
      console.log(couponDiscount,offer, totalAmount);
      offer = offer * totalAmount;
      amount = totalAmount - offer;
      amount = Number(amount)
    } else {
      amount = Number(totalAmount);
    }
    if (shippingCost) {
      amount = amount + shippingCost;
    }
    return Math.round(amount);
  }

  const couponCodeFixed = () => {
    if (couponDetails) {
       
      const filteredCoupons = couponDetails?.filter(coupon => coupon.code === couponCode);
      if (!filteredCoupons[0]) {
        alert("Invalid Coupon")
        return;
      }
      if (filteredCoupons[0]?.priceRange > Number(total)) {
        alert(`Purchase Above ${filteredCoupons[0]?.priceRange}`)
        return
      }
      let discount = filteredCoupons[0].discount;
      console.log(discount)
      setCouponDiscount(discount)
      setCouponId(filteredCoupons[0].id)
      setCouponRange(filteredCoupons[0]?.priceRange)
      // let offerPrice = Number(totalPrice) * discount;
      // setCouponPrice(offerPrice)
    } else {
      alert("Coupon Code not Avilable")
    }
  }

  const getDisCount = (totalAmount) => {
    let discount = couponDiscount / 100;
    console.log(couponDiscount)
    discount = (Number(totalAmount)) * discount;

    return Math.round(discount);
  }

  let taxAmount = 0


  if (loading) {
    return (<Loader active center />)
  }

  return (
    <React.Fragment>
      <Navbar />
{ cartProduct.length>0? <div>
  <div className="cart-page-container">
        <div style={{ marginLeft: "7%", marginRight: "7%", marginTop: "8%" }}>
          <div className="cart-container">
            <div>
              <Table attached='top' basic id="cart-table-container">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>PRODUCT</Table.HeaderCell>
                    <Table.HeaderCell>PRICE</Table.HeaderCell>
                    <Table.HeaderCell>QUANTITY</Table.HeaderCell>
                    <Table.HeaderCell>TOTAL</Table.HeaderCell>
                    <Table.HeaderCell>DELETE</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {cartProduct?.map((item) => {
                    let prize = item?.prize;
                    let oneItem = item?.prize;
                    if (item?.Discount > 0) {
                      let discount = item?.Discount * item?.quantity;
                      prize = prize - discount;
                      oneItem = oneItem - item?.Discount;
                    }
                    if (item?.tax > 0) {
                      taxAmount = taxAmount + ((prize * item.tax) / 100)
                      let tax = ((prize * item.tax) / 100);
                      prize = prize + tax;
                      let oneItemTax = ((oneItem * item.tax) / 100);
                      oneItem = oneItem + oneItemTax;
                    }
                    oneItem = Math.round(oneItem)
                    prize = oneItem * item?.quantity;
                    return (
                      <Table.Row>
                        <Table.Cell>
                          <Grid id="cart-name-image">
                            <GridColumn mobile={12} tablet={8} computer={8}>
                              <img id="card-product-image" style={{objectFit:"contain"}} src={item?.featureImage ? "https://s3.ap-south-1.amazonaws.com/business.strackit.com/" + item?.featureImage : null}></img>
                            </GridColumn>
                            <GridColumn mobile={12} tablet={8} computer={8} id="cart-prod-name">{item?.name}</GridColumn>
                          </Grid>
                        </Table.Cell>
                        <Table.Cell style={{ verticalAlign: "middle" }}>₹{Math.round(oneItem)}</Table.Cell>
                        <Table.Cell style={{ verticalAlign: "middle" }}>
                          <div style={{ display: "flex", textAlign: "center", justifyContent: "center" }}>
                            <button className="cart-button" onClick={() => { handleDecrease(item) }}>-</button>
                            <div style={{ width: "30%", border: "1px solid #e6e6e6", backgroundColor: "#f7f7f7", display: "flex", justifyContent: "center", alignItems: "center" }} >{item?.quantity}</div>
                            <button className="cart-button" onClick={() => { handleIncrease(item) }}>+</button>
                          </div>
                        </Table.Cell>
                        <Table.Cell style={{ verticalAlign: "middle" }}>
                          <div className="cart-total-prize">
                            ₹{prize.toFixed(2)}</div>
                        </Table.Cell>
                        <Table.Cell style={{ verticalAlign: "middle" }}>
                          <button className="cart-total-button"
                            onClick={() => {
                              setDeleteItem(item)
                              setAlertMessage(true)
                            }}
                            style={{ marginLeft: "0%", paddingLeft: "0%", marginTop: "0%", paddingRight: "0%", padding: "6%" }}> Remove </button>
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table>
            </div>
            <div className="cart-pay-container">
              <Card style={{ width: "100%", marginBottom: "2%" }} >
                <CardContent>
                  <h3 className="carttot-head">COUPON</h3>
                 
                   <>     { expiredCoupon ? <p style={{color:"red"}}>Coupon Code Not Available !!</p>:couponDetails?.map((item) => {
                    return (
                      <Card style={{ width: "100%", marginBottom: "2%" }} >
                        <CardContent style={{ padding: "0" }}>
                          <div id="coupon-cotainer">
                            <div>
                              <div style={{ color: "black", fontSize: "16px", fontWeight: "700" }}>
                                {item.code}
                              </div>
                              <div>Minmum Purchase {item.priceRange}</div>
                            </div>
                            {couponId && couponId == item.id ?
                              <Button
                                onClick={() => {
                                  setCouponDiscount(null);
                                  setCouponId(null)
                                }}
                                style={{ color: "white", backgroundColor: "red", borderRadius: "9999px" }}>Remove</Button>
                              : <Button
                                disabled={couponDiscount || item.priceRange >= Math.round(Number(total))}
                                onClick={() => {
                              
                                  if (!couponDiscount) {
                                    if(new Date()>new Date(item.validityTo)){
                                   
                                    }else{
                                      setCouponDiscount(item.discount);
                                      setCouponId(item.id)
                                      setCouponRange(item.priceRange)
                                    }
                                   
                                  }
                                }}
                                style={{ color: "white", backgroundColor: "#c87065", borderRadius: "9999px" }}>Apply</Button>}
                          </div>
                        </CardContent>
                      </Card>
                    )
                  })}
                  {couponDetails ? <div style={{ display: "flex", margin: "auto", justifyContent: "center" }} >
                    {expiredCoupon?<p></p>:<div >OR</div>}
                  </div> : null}</>
                  {expiredCoupon ?null: <div style={{ position: "relative", display: "flex", width: "100%" }}>
                    <Input
                      placeholder="COUPON CODE"
                      style={{ width: "100%", margin: "auto" }} >
                      <input
                        onChange={(e) => {
                          setCouponCode(e.target.value)
                          setCouponDiscount(null);
                          setCouponId(null)
                        }}
                      />
                      <Button
                        onClick={() => {
                          let couponId = couponCode;
                          if (couponId?.trim()) {
                            couponCodeFixed()
                          } else {
                            alert("Coupon Code is Empty")
                          }
                        }}
                        disabled={couponDiscount}
                        style={{ color: "white", backgroundColor: "#c87065" }}>Apply</Button>
                    </Input>
                  </div>}
                </CardContent>
              </Card>
              <h3 className="carttot-head">CART TOTALS</h3>
              <div style={{ display: "flex", paddingBottom: "3%" ,justifyContent:"space-between" }}>
                <div className="cart-subTotal">Subtotal:</div>
                <div className="cart-subTotal" style={{ marginLeft: "3%",justifyContent:"space-between" }}>₹{(total - taxAmount).toFixed(2)} </div>
              </div>
              {couponDiscount ? <div style={{ display: "flex", paddingBottom: "3%",justifyContent:"space-between" }}>
                <div className="cart-subTotal">Discount:</div>
                <div className="cart-subTotal" style={{ marginLeft: "3%" }}>₹{getDisCount(total)} </div>
              </div> : null}
              <div style={{ display: "flex", paddingBottom: "3%",justifyContent:"space-between" }}>
                <div className="cart-subTotal">Tax:</div>
                <div className="cart-subTotal" style={{ marginLeft: "3%" }}>₹{taxAmount.toFixed(2)} </div>
              </div>
              <div style={{ display: "flex", paddingBottom: "3%",justifyContent:"space-between" }}>
                <div className="cart-subTotal">Shipping Cost:</div>
                <div className="cart-subTotal" style={{ marginLeft: "3%" }}>₹{shippingCost?.purchaseRange > Number(total) ? shippingCost?.price : `0`} </div>
              </div>
              <div style={{ display: "flex", borderBottom: "1px dashed #d9d9d9", paddingBottom: "3%",justifyContent:"space-between" }}>
                <div className="cart-subTotal">Total:</div>
                <div className="cart-subTotal" style={{ marginLeft: "3%" }}>₹{shippingCost?.purchaseRange > Number(total) ? checkOffer(total, couponDiscount, shippingCost?.price) : checkOffer(total, couponDiscount, 0)}</div>
              </div>

              <div style={{ display: "flex", borderBottom: "1px dashed #d9d9d9", paddingBottom: "3%", paddingTop: "5%" }}>
                <div className="cart-subTotal">Address:</div>
                <div className="shipping-para" style={{ marginLeft: "3%" }}>
                  {!customerAddress ? "There are no shipping methods available. Please double check your address, or contact us if you need any help." : "If you want to update your new address"}
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", fontSize: "16px" }}>
                <div style={{ cursor: "pointer", color: "#717fe0", padding: "3% 0% 3% 0%" }}
                  onClick={() => {
                    
                    setAddAddress(true)
                    setCheckout(true)
                  }}
                >Add Address</div>
                {customerAddress ? <div style={{ cursor: "pointer", color: "#717fe0", padding: "3% 0% 3% 0%" }}
                  onClick={() => {
                    setAddressDialog(true)
                  }}
                >Change Delivery Address</div> : null}
              </div>
              {customerAddress ? <div>
                <div>
                  <Grid>
                    <GridColumn mobile={16} tablet={8} computer={8} >
                      <div>
                        <div>Name</div>
                        <Input style={{ width: "100%" }}
                          placeholder="Name"
                          value={`${checkout?"":customerName}`}
                          onChange={(e) => {
                            const value = e.target.value;
                           
                            const filteredValue = value.replace(/\d/g, '');
                            setCustomerName(filteredValue)
                            setCheckout(false)
                          }}
                        ></Input>
                        {err.Name&&<p style={{color:"red"}}>Please enter a valid name</p>}
                      </div>
                    </GridColumn>
                    <GridColumn mobile={16} tablet={8} computer={8} >
                      <div>
                        <div>Mobile Number</div>
                        <Input style={{ width: "100%" }}
                          placeholder="Mobile Number"
                          value={`${checkout?"":customerPhoneNumber}`}
                          onChange={(e) => {
                            const value = e.target.value;
                          
                            const filteredValue = value.replace(/\D/g, '')
                            setcustomerPhoneNumber(filteredValue)
                            setCheckout(false)
                          }}
                        ></Input>
                        {err.Mobile&&<p style={{color:"red"}}>Please enter valid  number</p>}
                      </div>
                    </GridColumn>
                    <GridColumn mobile={16} tablet={8} computer={8} >
                      <div><div>Steet</div>
                        <Input
                           placeholder="street"
                          value={`${checkout?"":customerStreet}`}
                          onChange={(e) => {
                            setCustomerStreet(e.target.value)
                            setCheckout(false)
                          }}
                        ></Input>
                        {err.Street&&<p style={{color:"red"}}>Please enter a valid street</p>}
                        </div>

                    </GridColumn>
                    <GridColumn mobile={16} tablet={8} computer={8} >
                      <div><div>City</div>
                        <Input
                          placeholder="City"
                          value={`${checkout?"":customerCity}`}
                          onChange={(e) => {
                            const value = e.target.value;

                            const filteredValue = value.replace(/\d/g, '');
                            setCustomerCity(filteredValue)
                            setCheckout(false)
                          }}
                        ></Input>
                        {err.City&&<p style={{color:"red"}}>Please enter a valid city</p>}
                        </div>
                    </GridColumn>
                    <GridColumn mobile={16} tablet={8} computer={8} >
                      <div><div>State</div>
                        <Input
                          placeholder="State"
                          value={`${checkout?"":customerState}`}
                          onChange={(e) => {
                            const value = e.target.value;
                           
                            const filteredValue = value.replace(/\d/g, '');
                            setCustomerState(filteredValue)
                            setCheckout(false)
                          }}
                        ></Input>
                        {err.State&&<p style={{color:"red"}}>Please enter a valid Street</p>}
                        </div>
                    </GridColumn>
                    <GridColumn mobile={16} tablet={8} computer={8} >
                      <div><div>Country</div>
                        <Input
                          placeholder="Country"
                          value={`${checkout?"":customerCountry}`}
                          onChange={(e) => {
                            const value = e.target.value;
                           
                            const filteredValue = value.replace(/\d/g, '');
                            setCustomerCountry(filteredValue)
                            setCheckout(false)
                          }}
                        ></Input>
                        {err.country&&<p style={{color:"red"}}>Please enter a valid country</p>}
                        </div>
                    </GridColumn>
                    <GridColumn mobile={16} tablet={8} computer={8} >
                      <div> <div>Pincode</div>
                        <Input
                          placeholder="Pincode"
                          value={`${checkout?"":customerPincode}`}
                          onChange={(e) => {setCustomerPincode(e.target.value)
                            setCheckout(false)
                          }}
                        ></Input>
                        {err.Pincode&&<p style={{color:"red"}}>Please enter a valid pincode</p>}
                        </div>
                      <div></div>
                    </GridColumn>

                  </Grid>

                </div>
                <center style={{marginTop:"20px"}}>
                  <button className="cart-total-button"
                    onClick={(e) => {
                      if (customerAddress) {
                        handleValidation(e)
                      }
                      else {
                        handleValidation(e)
 
                      }
                    }}
                  >{customerAllAddress.length <= 0 ? "ADD ADDRESS" : "UPDATE ADDRESS"} </button>
                </center>
              </div> : null}
              <PaymentComponent checkout={checkout} orderAddress={customerAddress}
                couponId={couponId}
                cartAmount={shippingCost?.purchaseRange > Number(total) ? checkOffer(total, couponDiscount, shippingCost?.price) : checkOffer(total, couponDiscount, 0)}
                getCartProductRefetch={getCartProductRefetch}
                siteConfig={siteConfig} />
            </div>

          </div>
        </div>
      </div>
      <Modal
        size="small"
        open={addAddress}
        onClose={() => { 
     
          setAddAddress(false) 
        }}
        style={{ top: '20%', transform: 'translateY(-20%)', maxHeight: '55vh', left: "50%", transform: 'translatex(-50%)' }}

      >
        <ModalHeader>Add Address</ModalHeader>
        <ModalContent>
          <Grid>
            <Grid.Column mobile={16} computer={8} tablet={8}>
              <div>Name</div>
              <Input style={{ width: "100%" }}
                placeholder="Name"
                
                onChange={(e) => {
                  const value = e.target.value;
                           
                    const filteredValue = value.replace(/\d/g, '');
                  setCustomerName(filteredValue)
                  setCheckout(true)
                }}
              ></Input>
            </Grid.Column>
            <Grid.Column mobile={16} computer={8} tablet={8}>
              <div>Mobile Number</div>
              <Input
                style={{ width: "100%" }}
                
                placeholder="Mobile Number"
                onChange={(e) => {
                  const value = e.target.value;
           
                  const filteredValue = value.replace(/\D/g, '')
                  setcustomerPhoneNumber(filteredValue)}}
              ></Input>
            </Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column mobile={16} computer={8} tablet={8}>
              <div>Steet</div>
              <Input
                style={{ width: "100%" }}
             
                placeholder="street"
                onChange={(e) => setCustomerStreet(e.target.value)}
              ></Input>
            </Grid.Column>
            <Grid.Column mobile={16} computer={8} tablet={8}>
              <div>City</div>
              <Input
                style={{ width: "100%" }}
                placeholder="City"
              
                onChange={(e) => {
                  const value = e.target.value;
                           
                  const filteredValue = value.replace(/\d/g, '');
                  setCustomerCity(filteredValue)}}
              ></Input>
            </Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column mobile={16} computer={8} tablet={8}>
              <div>State</div>
              <Input
                style={{ width: "100%", marginBottom: "3%" }}
                placeholder="State"
               
                onChange={(e) => {
                  const value = e.target.value;
                           
                 const filteredValue = value.replace(/\d/g, '');
                  setCustomerState(filteredValue)}}
              ></Input>
            </Grid.Column>
            <Grid.Column mobile={16} computer={8} tablet={8}>
              <div>Country</div>
              <Input
                placeholder="Country"
              
                style={{ width: "100%", marginBottom: "3%" }}
                onChange={(e) => {
                  const value = e.target.value;
                           
                  const filteredValue = value.replace(/\d/g, ''); 
                  setCustomerCountry(filteredValue)}}
              ></Input>
            </Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column mobile={16} computer={8} tablet={8}>
              <div>Pincode</div>
              <Input
                style={{ width: "100%", marginBottom: "3%" }}
                
                placeholder="Pincode"
                onChange={(e) => setCustomerPincode(e.target.value)}
              ></Input>
            </Grid.Column >
          </Grid>
        </ModalContent>
        <ModalActions>
          <Button autoFocus
            style={{ backgroundColor: "#717fe0", color: "white", marginRight: "3%" }}
            onClick={(e) => {
              setAddAddress(false)
           addAddressIn()
            }}
          >ADD ADDRESS
          </Button>
          <Button
            style={{ backgroundColor: "#717fe0", color: "white" }}
            onClick={() => {
              setAddAddress(false)
            }}
          >Close
          </Button>

        </ModalActions>
      </Modal>

      <Modal
        size="small"
        open={addressDialog} onClose={() => setAddressDialog(false)}
        style={{ top: '20%', transform: 'translateY(-20%)', maxHeight: '55vh', left: "50%", transform: 'translatex(-50%)' }}
        centered
      >
        <ModalHeader>Change Delivery Address</ModalHeader>
        <ModalContent>
          {customerAllAddress.map((address, index) => {
            return (
              <Grid>
                <Grid.Column mobile={2} computer={2} tablet={2}>
                  <Checkbox
                    checked={customerId == address.id ? true : false}
                    onChange={() => {
                      setCustomerCity(address?.city)
                      setCustomerName(address?.name)
                      setcustomerPhoneNumber(address?.phone)
                      setCustomerName(address?.name)
                      setCustomerStreet(address?.street)
                      setCustomerState(address?.state)
                      setCustomerPincode(address?.pincode)
                      setCustomerId(address?.id)
                      setCustomerAddress(address)
                      setAddressDialog(false)
                    }}
                  />
                </Grid.Column>
                <Grid.Column mobile={14} computer={14} tablet={14}>
                  <div>Name : {address?.name}</div>
                  <div>Address : {address?.street} {address.city},{address?.state}</div>
                  <div>{address?.state}-{address?.pincode}</div>
                  <div>Phone Number: {address?.phone}</div>
                </Grid.Column>
              </Grid>
            )
          })}
        </ModalContent>
        <ModalActions>
          <Button autoFocus
            style={{ backgroundColor: "#717fe0", color: "white", marginRight: "3%" }}
            onClick={() => { setAddressDialog(false) }}
          >Close
          </Button>
        </ModalActions>
      </Modal>
      <Dialog
        open={alertMessage}
        onClose={() => { setAlertMessage(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>Are you sure you want to delete your Item?</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus
            style={{ backgroundColor: "#717fe0", color: "white", marginRight: "3%" }}
            onClick={() => {
              setAlertMessage(false)
              onDelete(deleteItem)
            }}
          >
            Delete
          </Button>
          <Button
            style={{ backgroundColor: "#717fe0", color: "white" }}
            onClick={() => {
              setAlertMessage(false)
              setDeleteItem(null)
            }}
            autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
     
  </div>:<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', margin: "auto" }}>
                    <center style={{ textAlign: 'center', }}>
                        <img style={{ height: "60%", width: "60%" }} src={NoOrder} />
                        <div style={{ fontSize: "17px", fontWeight: "600" }} >Cart Is Empty</div>
                        {userId() ? null : <div style={{ fontSize: "17px", fontWeight: "600" }} >Login To Show Orders</div>}
                    </center>
                    
                    </div> }
                    <Footer />
    </React.Fragment >
  )
}