import React, { useContext, useEffect, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import userId from '../../UserId';
import GlobalContext from '../../GlobalContext';
import { Card, GridColumn } from 'semantic-ui-react';
import { FaHeart } from 'react-icons/fa6';
import { CiHeart } from 'react-icons/ci';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { SingleProduct } from '../SingleProduct';
import { useDispatch, useSelector } from 'react-redux';
import { addproducts,addFiltered } from '../../slice/prductSlice';
import { BabelLoading } from 'react-loadingg';
const GET_PRODUCTS = gql`
query ProductsByCategory($filter: CategoryWiseFilter) {
    productsByCategory(filter: $filter) {
      name
      id
      localName
      hsnCode
      tax
      prize
      dnp
      discount
      featureImage
      description
      noStock
      wishList {
        like
      }
      isAddedToCart {
        inCart
      }
      quantity {
        quantity
      }
      productId
      shopId
    }
  }
`;
const GET_MASTER_CATEGORIES = gql`
query MasterCategories($filter: MasterCategoryInput) {
    masterCategories(filter: $filter) {
      shopId
      category
      image
      id
      position
      status
      addedon
    }
  }
`;
const ADD_TO_WHISLIST = gql`mutation Mutation($userId: Int, $productId: Int, $shopId: Int, $delete: Boolean) {
    Wishlist(userId: $userId, productId: $productId, shopId: $shopId, Delete: $delete) {
      productId
    }
  }`
let startingIndex=0;
let endingIndex=10;

function Allproduct({category,setCategoryLength}) {
   
    const context = useContext(GlobalContext);
    const [masterCategory, setMasterCategory] = useState([])
    const {filterd}=useSelector(state=>state.data)
    const [isZoomed, setIsZoomed] = useState(false);
    const dispatch=useDispatch()
    const[length,setLength]=useState({})
    const[products,setProducts]=useState([])
    const[product,setProduct]=useState(null)
    const [page,setPage]=useState(1)
    let ProductperPage=12
    const [openDialog, setOpenDialog] = useState(false)
    const [addToLikeMutation, { loading: addToLikeLoading, error: addToLikeError }] = useMutation(ADD_TO_WHISLIST);
    const { loading: getMasterCategoriesLoading, error: getMasterCategoriesError, data: getMasterCategoriesData, refetch: getMasterCategoriesRefetch } = useQuery(GET_MASTER_CATEGORIES, {
        variables: {
            "filter": {
                "shopId": context.shopId,
            }
        },
    });
    const { loading: getProductsLoading, error: getProductsError, data: getProductsData, refetch: getProductsRefetch } = useQuery(GET_PRODUCTS, {
        variables: {
            "filter": {
                "shopId": context.shopId,
                "secondary": null,
                "master": "accessories",
                "userId": userId()
            }
        },
    });
    const { loading: getProductsLoading1, error: getProductsError1, data: getProductsData1, refetch: getProductsRefetch1 } = useQuery(GET_PRODUCTS, {
        variables: {
            "filter": {
                "shopId": context.shopId,
                "secondary": null,
                "master": "mens" ,
                "userId": userId()
            }
        },
    });
    const { loading: getProductsLoading2, error: getProductsError2, data: getProductsData2, refetch: getProductsRefetch2 } = useQuery(GET_PRODUCTS, {
        variables: {
            "filter": {
                "shopId": context.shopId,
                "secondary": null,
                "master": "womens" ,
                "userId": userId()
            }
        },
    });
  
    const handleLikeClick = (product) => {
        if (!userId()) {
            window.location.href = "https://you.strackit.com/?redirectto=" + window.location.href;
        } else {
            // setLoading(true)
            addToLikeMutation({
                variables: {
                    userId: userId(),
                    productId: Number(product.id),
                    shopId: product.shopId,
                    delete: product.wishList.like
                },
            })
                .then((response) => {
                    if (getProductsRefetch) {
                        getProductsRefetch()
                    }
                    // setLoading(false)
                })
                .catch((mutationError) => {
                    // setLoading(false)
                    console.error('Mutation error:', mutationError);
                });
        }
    }
  
    useEffect(() => {
        if (getMasterCategoriesData && getMasterCategoriesData.masterCategories && context.shopId) {
          setMasterCategory(getMasterCategoriesData.masterCategories)
        }
        console.log(masterCategory[0],"master")
        if(getProductsData){
            setProducts(getProductsData.productsByCategory)
            setLength({"accessories":getProductsData.productsByCategory.length})
        }
        if(getProductsData1){
        setProducts(products=>([...products,...getProductsData1.productsByCategory]))
        setLength(length=>({...length,"mens":getProductsData1.productsByCategory.length}))
        }
        if(getProductsData2){
            setProducts(products=>([...products,...getProductsData2.productsByCategory]))
            setLength(length=>({...length,"womens":getProductsData2.productsByCategory.length}))
            
            }
           
            if(category=="Accessories" && getProductsData &&getProductsData.productsByCategory && context.shopId){
               
                setProducts(getProductsData.productsByCategory)
             
            }
            if(category=="Mens" && getProductsData1 &&getProductsData1.productsByCategory && context.shopId){
         
                setProducts(getProductsData1.productsByCategory)
            }
            if(category=="Womens" && getProductsData2 &&getProductsData2.productsByCategory && context.shopId){
               
                
                setProducts(getProductsData2.productsByCategory)
              
            }
           
      }, [getMasterCategoriesData,getProductsData,getProductsData1,getProductsData2,page,context.shopId,category])
     console.log(length,"length")
      useEffect(()=>{
        if(products.length>0){
            dispatch(addproducts(products))
            setCategoryLength({...length})
        }
        if(filterd.length>0){
            dispatch(addFiltered(products))
        }
        
      },[products,category])
  
      const handleChange = (event, newPage) => {

        if (newPage !== null) { 
            setPage(newPage);
            window.scrollTo({
                top:900,
                behavior:"smooth"
            })
        }
       
    };

   

    const handleClose = () => {
        setOpenDialog(false)
        setIsZoomed(false)
    }

if(getProductsLoading){
    return(
     <p style={{textAlign:"center"}}>
           <BabelLoading/>
     </p>
    )
}
  return (
    <React.Fragment >
        <div className='showing' style={{position:"absolute", top:"-50px",fontSize:"16px"}}>Showing {page*ProductperPage-ProductperPage==0?page+1*ProductperPage-ProductperPage:page*ProductperPage-ProductperPage}-{page*ProductperPage>products.length?products.length:page*ProductperPage} of {filterd.length>0?filterd.length:products.length} results</div>
        { filterd.length>0?filterd.map(item=>{
            if (!item) {
                return;
            }
            let prize = item?.prize;
            if (item?.discount > 0) {
                let discount = item?.discount
                prize = prize - discount;
            }
            if (item?.tax > 0) {
                let tax = ((prize * item.tax) / 100);
                prize = prize + tax;
            }
            return (
 <GridColumn  mobile={16}  computer={5} tablet={8}>
                    <Card  style={{ width: "100%", height: "24rem", margin: "0" }} >
                        <div
                            className={`image-container ${isZoomed ? 'zoomed' : ''}`}
                            onMouseEnter={() => setIsZoomed(true)}
                            onMouseLeave={() => setIsZoomed(false)}
                        >
                            <img
                                onClick={() => {
                                    setOpenDialog(true)
                                    setProduct(item)
                                }}
                                style={{ width: "100%", height: "100%", cursor: "pointer" }} className="zoomable-image" src={item?.featureImage ? "https://s3.ap-south-1.amazonaws.com/business.strackit.com/" + item?.featureImage : "demo"}></img>
                            <div className="overlay">
                                <button className="product-button"
                                    onClick={() => {
                                        setOpenDialog(true)
                                        setProduct(item)
                                       
                                    }}
                                >Quick View</button>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: '2%', padding: "4%" }}>
                            <div className="product-name">{item?.name}</div>
                            <div className="product-heart" onClick={() => {
                                handleLikeClick(item)
                            }} style={{ fontSize: "20px" }}>{item?.wishList?.like ? <FaHeart style={{ color: "#717fe0", fontSize: "16px" }} /> :
                                <CiHeart />}</div>
                        </div>
                        <div style={{ fontSize: "14px", padding: "4%" }}>₹{Math.round(prize)}</div>
                    </Card>
                    </GridColumn>
                   
                    )
        }):products?.slice(page*ProductperPage-ProductperPage,page*ProductperPage).map((item) => {
                if (!item) {
                    return;
                }
                let prize = item?.prize;
                if (item?.discount > 0) {
                    let discount = item?.discount
                    prize = prize - discount;
                }
                if (item?.tax > 0) {
                    let tax = ((prize * item.tax) / 100);
                    prize = prize + tax;
                }
                return (
     <GridColumn  mobile={16}  computer={5} tablet={8}>
                        <Card style={{ width: "100%", height: "24rem", margin: "0" }} >
                            <div
                                className={`image-container ${isZoomed ? 'zoomed' : ''}`}
                                onMouseEnter={() => setIsZoomed(true)}
                                onMouseLeave={() => setIsZoomed(false)}
                            >
                                <img
                                    onClick={() => {
                                        setOpenDialog(true)
                                        setProduct(item)
                                    }}
                                    style={{ width: "100%", height: "100%", cursor: "pointer" }} className="zoomable-image" src={item?.featureImage ? "https://s3.ap-south-1.amazonaws.com/business.strackit.com/" + item?.featureImage : "demo"}></img>
                                <div className="overlay">
                                    <button className="product-button"
                                        onClick={() => {
                                            setOpenDialog(true)
                                            setProduct(item)
                                        }}
                                    >Quick View</button>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: '2%', padding: "4%" }}>
                                <div className="product-name">{item?.name}</div>
                                <div className="product-heart" onClick={() => {
                                    handleLikeClick(item)
                                }} style={{ fontSize: "20px" }}>{item?.wishList?.like ? <FaHeart style={{ color: "#717fe0", fontSize: "16px" }} /> :
                                    <CiHeart />}</div>
                            </div>
                            <div style={{ fontSize: "14px", padding: "4%" }}>₹{Math.round(prize)}</div>
                        </Card></GridColumn>)

                        })}
  
                        <div style={{padding:"4% 2% 4% 2%",display:"flex",justifyContent:"center",position:"absolute",bottom:"-100px"}}>
                       { filterd.length>0?null:<Stack  spacing={2}>
                        <Pagination 
                        className='page'
                count={Math.round((products.length/ProductperPage)+0.4)}              // Total number of pages
                page={page}             // Always pass a defined page value
                onChange={handleChange} // Handle page change
                variant="outlined"
                shape="rounded"
            />                        </Stack>}
                        </div>
                        <SingleProduct open={openDialog} handleClose={handleClose} product={product} getProductsRefetch={getProductsRefetch} />

                        </React.Fragment>  

  )
}

export default Allproduct
