import React, { Component } from "react";
import DOMPurify from 'dompurify';
import { Navbar } from "../Navbar";
import { Footer } from ".";


class CancellationAndRefund extends Component {
    constructor(props) {
        super(props);

    }


    render() {
        console.log("this", this.props.customerService);
        const sanitizedHTML = DOMPurify.sanitize(this.props?.customerService?.cancellationPolicy);
        return (
            <React.Fragment>
                <Navbar />
                <div style={{ margin: "3% 15% 4% 7%", fontSize: "16px", padding: "5%" }}>
                    <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}

export default CancellationAndRefund;