import React, { useContext, useState, useEffect, useRef } from "react";
import { gql, useQuery } from "@apollo/client";
import GlobalContext from "../../GlobalContext";
import "../../Assets/css/SecondCategory.css"
import { Button, Grid, Icon, Input, Search } from 'semantic-ui-react'
import { IoFilter } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { Product } from "../Products";
import { useParams } from "react-router-dom";
import userId from "../../UserId";
import { Navbar } from "../../Navbar";
import { Footer } from "../../Footer";
import NoProductImage from "../../Assets/Images/NoProductImage.png"
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { MasterProduct } from "../MasterProducts";
import { Loader } from "../Loader";
import { BabelLoading } from "react-loadingg";

const GET_SECONDARY_CATEGORIES = gql`
query SecondaryCategories($filter: categoryFilter) {
    secondaryCategories(filter: $filter) {
      id
      shopId
      mid
      category
      image
      position
    }
  }
`;

const GET_MASTER_CATEGORIES = gql`
query MasterCategories($filter: MasterCategoryInput) {
    masterCategories(filter: $filter) {
      shopId
      category
      image
      id
      position
      status
      addedon
    }
  }
`;

const GET_PRODUCTS = gql`
query ProductsByCategory($filter: CategoryWiseFilter) {
    productsByCategory(filter: $filter) {
      name
      id
      localName
      hsnCode
      tax
      prize
      dnp
      discount
      featureImage
      description
      noStock
      wishList {
        like
      }
      isAddedToCart {
        inCart
      }
      quantity {
        quantity
      }
      productId
      shopId
    }
  }
`;

export const SecondaryCategory = ({ navbar }) => {
    const context = useContext(GlobalContext);
    const { productName } = useParams();
    const [secondaryCategory, setSecondaryCategory] = useState([]);
    const [products, setProducts] = useState([])
    const [masterCategoryId, setMasterCategoryId] = useState(null)
    const [masterCategory, setMasterCategory] = useState(null);
    const [secondaryProduct, setSecondaryProduct] = useState(null);
    const [categoryData, setCategoryData] = useState([]);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState(null);
    const [searchWord, setSearchWord] = useState(null)
    const [loading, setLoading] = useState(false);

    const scrollContainerRef = useRef(null);

    const handleScroll = (direction) => {
        const scrollAmount = direction === 'left' ? -100 : 100;
        scrollContainerRef.current.scrollLeft += scrollAmount;
    };



    const { loading: getMasterCategoriesLoading, error: getMasterCategoriesError, data: getMasterCategoriesData, refetch: getMasterCategoriesRefetch } = useQuery(GET_MASTER_CATEGORIES, {
        variables: {
            "filter": {
                "shopId": context.shopId,
            }
        },
    });

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' instead of 'smooth' for instant scrolling
        });
    }, [productName])

    useEffect(() => {
        if (getMasterCategoriesData && context?.shopId) {
            let resultObject = null;
            if (productName) {
                setMasterCategory(productName)
                setSecondaryProduct(null)
                resultObject = getMasterCategoriesData.masterCategories.find(item => item.category === productName);
                console.log("getMasterCategoriesData", getMasterCategoriesData);
            }
            let id = null;
            if (resultObject) {
                console.log("resultObject", resultObject);
                id = resultObject.id;
            }
            if (!productName) {
                setMasterCategory(getMasterCategoriesData.masterCategories[0].category)
            }
            setMasterCategoryId(id)
        }
    }, [getMasterCategoriesData, productName, context?.shopId])




    const { loading: getSecondaryCategoryLoading, error: getSecondaryCategoryError, data: getSecondaryCategoryData, refetch: getSecondaryCategoryRefetch } = useQuery(GET_SECONDARY_CATEGORIES, {
        variables: {
            "filter": {
                "shopId": context.shopId,
                "masterCategoryId": masterCategoryId ? Number(masterCategoryId) : categoryData[0]?.id,
            }
        },
    });
    const filterVariables = {
        "filter": {
            "shopId": context.shopId,
            "secondary": secondaryProduct ? secondaryProduct : null,
            "master": !secondaryProduct ? masterCategory : null,
            "userId": userId()
        }
    };


    const { loading: getProductsLoading, error: getProductsError, data: getProductsData, refetch: getProductsRefetch } = useQuery(GET_PRODUCTS, {
        variables: {
            "filter": {
                "shopId": context.shopId,
                "secondary": secondaryProduct ? secondaryProduct : null,
                "master": !secondaryProduct ? masterCategory : null,
                "userId": userId()
            }
        },
    });



    useEffect(() => {
        if (context?.shopId) {
            if (getProductsRefetch) {
                getProductsRefetch()
            }
            if (getMasterCategoriesRefetch) {
                getMasterCategoriesRefetch()
            }
            if (getMasterCategoriesData) {
                setCategoryData(getMasterCategoriesData.masterCategories)
            }
            if (getSecondaryCategoryData) {
                setSecondaryCategory(getSecondaryCategoryData.secondaryCategories)
            }
            if (getProductsLoading) {
                setLoading(true)
            }
            if (getProductsData) {
                setLoading(false)
                if (searchTerm) {
                    setProducts(products.filter(product => product?.name.toLowerCase().includes(searchTerm.toLowerCase())))
                } else {
                    setProducts(getProductsData.productsByCategory)
                }
            }
            if (getSecondaryCategoryRefetch) {
                getSecondaryCategoryRefetch()
            }
        }
        console.log("pro",products)

    }, [getSecondaryCategoryData, getProductsData, secondaryProduct, getProductsRefetch, productName, getMasterCategoriesRefetch, searchTerm, context?.shopId]);

    const wrapperStyle = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        marginTop: '0',
        paddingBottom: '11px',
        paddingTop: '2px',
        overflowX: 'auto',
        gap: "4%",// To enable horizontal scrolling if content overflows
    };

    useEffect(() => {
        setSearchTerm(null);
        setIsSearchOpen(false)
    }, [productName])

    // if (loading) {
    //     return (
    //         <Loader />
    //     )
    // }


    return (
        <React.Fragment>
            {navbar ? <Navbar /> : null}

            <div style={productName ? { margin: "9% 5% 6% 5%" } : { margin: "0% 1% 1% 0%" }} >
                {/* <div className="secondary-category-container" > */}
                {productName ? <div className="product-master">   <MasterProduct /></div> : null}
                <div className="secondary-category-contents" style={{ marginBottom: "3%" }}>
                    <div style={{ display: "flex" }}>
                        <FaChevronLeft className="secondary-arrow" onClick={() => handleScroll("left")} />
                        <div className="scroll-contents" ref={scrollContainerRef}>
                            <button
                                id={!secondaryProduct ? "select-secondary" : null}
                                className="secondary-item" onClick={() => {
                                    if (!productName) {
                                        setMasterCategory(getMasterCategoriesData.masterCategories[0].category)
                                    } else {
                                        setMasterCategory(productName);
                                    }
                                    setSecondaryProduct(null)
                                }}>
                                All Products
                            </button>
                            {secondaryCategory?.map((item) => {
                                return (
                                    <button
                                        onClick={() => {
                                            setSecondaryProduct(item.category)
                                            setSearchTerm("")
                                            setMasterCategory(null)
                                        }}
                                        id={secondaryProduct == item.category ? "select-secondary" : null}
                                        className="secondary-item">{item.category}</button>
                                )
                            })}
                        </div >
                        <FaChevronRight className="secondary-right-arrow" onClick={() => handleScroll("right")} />
                    </div>
                    {/* <button  onClick={() => handleScroll("right")}>
                        {"right" === 'left' ? '<' : '>'}
                    </button> */}
                    <div className="product-button-container">
                        {/* <span><Button onClick={() => { setIsFilterOpen(!isFilterOpen) }} style={{ width: "40%", backgroundColor: "white", border: "1px solid #e6e6e6" }}>
                            <IoFilter style={{ marginRight: "10%" }} />Filter</Button>
                        </span> */}
                        <span> <Button
                            id="custom-button"
                            onClick={() => {
                                setSearchTerm(null)
                                setIsSearchOpen(!isSearchOpen)
                            }}
                        >
                            <IoIosSearch id="custom-button-icon" />Search</Button></span>
                    </div>
                </div>
                <div className={`search-box ${isSearchOpen ? "open" : ""}`}>
                    <Input value={searchTerm} style={{ width: "100%" }} icon placeholder='Search...'>
                        <input style={{ padding: "2%" }}

                            onChange={(e) => {
                                setSearchTerm(e.target.value)
                            }}
                        />
                        <Icon name='search' style={{ cursor: "pointer" }}

                        />
                    </Input>
                </div>
                <div className={`filter-box ${isFilterOpen ? "open" : ""}`}>
                    <div style={{ width: "100%", height: "16rem" }}>

                    </div>
                </div>
                {loading ? <div> <BabelLoading style={{ position: "relative", margin: "auto", marginTop: "7%", marginBottom: "8%" }} /></div> : products?.length <= 0 ? <div style={{ margin: "auto", textAlign: "center" }}><img style={{ width: "30%" }} src={NoProductImage}></img></div>
                    : <Grid>
                        <Product products={products} getProductsRefetch={getProductsRefetch} searchTerm={searchTerm} setProducts={setProducts} />
                    </Grid>}
                {/* </div> */}
            </div>
            {navbar ? <Footer /> : null}
        </React.Fragment >
    )
}