import React, { useContext, useEffect, useState } from "react";
import "../Assets/css/navbar.css";
import { IoIosSearch } from "react-icons/io";
import Box from '@mui/material/Box';
import { IoMdCart } from "react-icons/io";
import { CiHeart } from "react-icons/ci";
import { Link } from "react-router-dom";
import { Input, Icon } from "semantic-ui-react";
import { AiOutlineMenu } from "react-icons/ai";
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { TbLogin } from "react-icons/tb";
import Cookies from "js-cookie";
import userId, { DOMAIN } from "../UserId";
import { IoIosLogOut } from "react-icons/io";
import GlobalContext from "../GlobalContext";
import { gql, useQuery, useMutation } from "@apollo/client";


const GET_SHOPNAME = gql`
query Shop($filter: ShopInput) {
  shop(filter: $filter) {
    customDomain
    area
    city
    state
    name
  }
}`;

export const Navbar = () => {
    const [openMenu, setOpenMenu] = useState(false)
    const [login, setLogin] = useState(false);
    const context = useContext(GlobalContext);
    // const [searchTerm, setSearchTerm] = useState("")

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 50;
            if (isScrolled !== scrolled) {
                setScrolled(isScrolled);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrolled]);

    useEffect(() => {
        let loginValue = Cookies.get("ualum") && true ? true : false;
        setLogin(loginValue)
    }, [])


    const { loading: getShopNameLoading, error: getShopNameError, data: getShopName } = useQuery(GET_SHOPNAME, {
        variables: {
            "filter": {
                id: context.shopId
            }
        },
    });

    let navContents = [
        {
            href: "/",
            name: "Home"
        },
        {
            href: "/whisList",
            name: "My WishList"
        },
        {
            href: "/cart",
            name: "My Cart"
        },
        {
            href: "/myorders",
            name: "My Order"
        },
    ]

    const list = (anchor) => {
        return (
            <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
                role="presentation"
            // onClick={toggleDrawer(anchor, false)}
            // onKeyDown={toggleDrawer(anchor, false)}

            >
                <List>
                    <React.Fragment>
                        <Link to="/" style={{ color: "black" }} >
                            <ListItemButton
                                onClick={() => { setOpenMenu(false) }}
                            >
                                <ListItemText
                                    style={{ textAlign: "center" }}
                                    primary="Home" />

                            </ListItemButton>
                        </Link>
                        {!userId() ? <ListItemButton
                            onClick={() => {
                                window.location.href = "https://you.strackit.com/?redirectto=" + window.location.href;
                            }}
                        >
                            <ListItemText
                                style={{ textAlign: "center" }}
                                primary="My WishList" />

                        </ListItemButton> : <Link to="/whisList" style={{ color: "black" }} >
                            <ListItemButton
                                onClick={() => { setOpenMenu(false) }}
                            >
                                <ListItemText
                                    style={{ textAlign: "center" }}
                                    primary="My WishList" />

                            </ListItemButton>
                        </Link>}
                        {!userId() ? <ListItemButton
                            onClick={() => {
                                window.location.href = "https://you.strackit.com/?redirectto=" + window.location.href;
                            }}
                        >
                            <ListItemText
                                style={{ textAlign: "center" }}
                                primary="My Cart" />

                        </ListItemButton> : <Link to="/cart" style={{ color: "black" }} >
                            <ListItemButton
                                onClick={() => { setOpenMenu(false) }}
                            >
                                <ListItemText
                                    style={{ textAlign: "center" }}
                                    primary="My Cart" />

                            </ListItemButton>
                        </Link>}
                        {!userId() ? <ListItemButton
                            onClick={() => {
                                window.location.href = "https://you.strackit.com/?redirectto=" + window.location.href;
                            }}
                        >
                            <ListItemText
                                style={{ textAlign: "center" }}
                                primary="My Order" />

                        </ListItemButton> : <Link to="/myorders" style={{ color: "black" }} >
                            <ListItemButton
                                onClick={() => { setOpenMenu(false) }}
                            >
                                <ListItemText
                                    style={{ textAlign: "center" }}
                                    primary="My Order" />

                            </ListItemButton>
                        </Link>}
                        <Divider />
                    </React.Fragment>
                    {!userId() ?
                        <Link
                            onClick={() => {
                                window.location.href = "https://you.strackit.com/?redirectto=" + window.location.href;
                            }}
                            style={{ color: "black" }}
                        >
                            <ListItemButton
                                onClick={() => { setOpenMenu(false) }}
                            >
                                <ListItemText
                                    style={{ textAlign: "center" }}
                                    primary="My Login" />
                            </ListItemButton>
                        </Link> :  <Link onClick={() => {
                            userLogOut()
                        }} style={{ color: "black" }}>
                            <ListItemButton>
                            <ListItemText
                                style={{ textAlign: "center" }}
                                primary="Logout" />
                        </ListItemButton>
                            </Link> }
                <Divider />
                <ListItemButton
                    onClick={() => { setOpenMenu(false) }}
                >
                    <ListItemText
                        style={{ textAlign: "center" }}
                        primary="Close" />

                </ListItemButton>


                <Divider />
            </List>
            </Box >
        )
    }

const userLogOut = async () => {
    await Cookies.remove('ualum');
    window.location.href = window.location.href;
}

return (
    <React.Fragment>
        <div className={`navbar-container ${scrolled ? 'scrolled' : ''}`}>

            <Link to="/" style={{ color: "black" }} className="navbar-logo">
                <span style={{ fontWeight: "700", marginRight: "4%" }}>{getShopName?.shop ? getShopName?.shop[0].name.toUpperCase() : null}</span>
            </Link>

            <div className="navbar-contents-container">
                <Link to="/" style={{ color: "black" }}>
                    <div className="navbar-content">
                        Home
                    </div>
                </Link>
                {!userId() ? <div
                    onClick={() => {
                        window.location.href = "https://you.strackit.com/?redirectto=" + window.location.href;
                    }}
                >
                    <div className="navbar-content">
                        My WishList
                    </div>

                </div> : <Link to="/whisList" style={{ color: "black" }}>
                    <div className="navbar-content">
                        My WishList
                    </div>
                </Link>}
                {!userId() ? <div
                    onClick={() => {
                        window.location.href = "https://you.strackit.com/?redirectto=" + window.location.href;
                    }}
                >
                    <div className="navbar-content">
                        My Cart
                    </div>

                </div> : <Link to="/cart" style={{ color: "black" }}>
                    <div className="navbar-content">
                        My Cart
                    </div>
                </Link>}
                {!userId() ? <div
                    onClick={() => {
                        window.location.href = "https://you.strackit.com/?redirectto=" + window.location.href;
                    }}
                >
                    <div className="navbar-content">
                        My Order
                    </div>

                </div> : <Link to="/myorders" style={{ color: "black" }}>
                    <div className="navbar-content">
                        My Order
                    </div>
                </Link>}
            </div>
            <div className="navbar-icons">
                {/* <div className="navbar-icon nav-input"><Input style={{ width: "100%", height: "100%", fontSize: "13px" }} icon placeholder='Search...'>
                        <input style={{ width: "90%" }} />
                        <Icon name='search' />
                    </Input>
                    </div>
                    <Link to="/cart" style={{ color: "black", marginRight: "3%" }} > <div className="navbar-icon"><IoMdCart /></div></Link>
                    <Link to="/whisList" style={{ color: "black" }} ><div className="navbar-icon"><CiHeart /></div>
                    </Link>

                    <div className="navbar-icon" >

                    </div> */}
                {!userId() ? <Link
                    onClick={() => {
                        window.location.href = "https://you.strackit.com/?redirectto=" + window.location.href;
                    }}
                    style={{ color: "black", marginRight: "3%" }}
                >
                    <div style={{ fontSize: '15px', fontWeight: 'bold' }}>Login</div>
                </Link> : <Link onClick={() => {
                    userLogOut()
                }} style={{ color: "black", marginRight: "3%" }}>
                    <div style={{ fontSize: '15px', fontWeight: 'bold' }}
                    >Logout</div>
                </Link>}
                <div className="navbar-icon mob-menu"
                    onClick={() => {
                        setOpenMenu(true)
                    }}
                ><AiOutlineMenu /></div>
            </div>

        </div>
        <Drawer
            anchor="left"
            open={openMenu}
            onClose={() => { setOpenMenu(false) }}
        >
            {list("left")}
        </Drawer>
    </React.Fragment>
)
}