import React from 'react'
import { Parallax, Background } from 'react-parallax';
import './parralax.css'
function ParallaxEffect({item}) {
    console.log(item)
    // console.log(item[0],"item")
    // const url=item?"https://s3.ap-south-1.amazonaws.com/business.strackit.com/"+item[0].image:""

   return  (
    <div style={{position:"relative"}}>
      <div  className="parallax2"
            style={{ backgroundImage: `url(https://s3.ap-south-1.amazonaws.com/business.strackit.com/${item?.image})` }} >
      </div>
      <div className="content" style={{position:"absolute",top:"200px",left:"200px"}}>
        <p className='p' style={{fontSize:"40px",color:"white",fontWeight:"bold",maxWidth:"500px"}}>{item?.title}</p>
      </div>
    </div>
   )
  
}

export default ParallaxEffect
