import React from "react";
import { BabelLoading } from "react-loadingg";
import "../../Assets/css/SecondCategory.css"

export const Loader = () => {
    return (
        <div>
            <BabelLoading />
        </div>
    )
} 