import { gql } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import useRazorpay from "react-razorpay";
import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import GlobalContext from "../../GlobalContext"
import userId from "../../UserId";
import { SuccessAlert } from "../SingleProduct/SuccessAlert";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Button } from "@mui/material";

export default function PaymentComponent({ cartAmount,couponId, siteConfig, orderAddress, getCartProductRefetch }) {
    const context = useContext(GlobalContext);
    const [razorpayRes, setRazorpayRes] = useState()
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(false)
    const [Razorpay, isLoaded] = useRazorpay();
    const ORDER_BY_CART = gql`
  mutation OrderbyCart($userId: Int, $shopId: Int, $voucherNo: String, $orderType: String, $customerId: Int, $customerName: String, $customerMobile: String, $customerAddress: String, $pickuptime: DateTime, $feedback: String, $shopPhone: String, $rating: Int) {
    OrderbyCart(userId: $userId, shopId: $shopId, voucherNo: $voucherNo, orderType: $orderType, customerId: $customerId, customerName: $customerName, customerMobile: $customerMobile, customerAddress: $customerAddress, pickuptime: $pickuptime, feedback: $feedback, shopPhone: $shopPhone, rating: $rating) {
      id
      shopId
      orderType
      customerId
      customerName
      customerMobile
      customerAddress
      customerGstin
      userOrder
      pickuptime
      rating
      feedback
      paymentInfo
    }
  }
  `
    const [orderProduct, { loading: orderProductLoading, error: orderProductError }] = useMutation(ORDER_BY_CART);

    const handleAddAddress = async (orderAddress) => {

        try {
            orderProduct({
                variables: {
                    "userId": userId(),
                    "shopId": context.shopId,
                    "voucherNo": "1",
                    "orderType": "cart",
                    "customerId": userId(),
                    "customerName": orderAddress.name,
                    "customerMobile": orderAddress.phone,
                    couponId,
                    "customerAddress": `${orderAddress.street} ${orderAddress.city},${orderAddress.state},${orderAddress.country}-${orderAddress.pincode}`,
                    "pickuptime": "",
                    "feedback": "",
                    "rating": 1,
                    "shopPhone": "123456789",
                },
            })
                .then((response) => {
                    setOpenAlert(true);
                    getCartProductRefetch()
                    // setShowSpinner(false);
                    // if (getCartRefetch) {
                    //     getCartRefetch()
                    // }
                })
                .catch((mutationError) => {
                    //     setShowSpinner(false);
                    alert("2")
                    // Handle any errors that occurred during the mutation.
                    console.error('Mutation error:', mutationError);
                });
        } catch (err) {
            alert(err)
        }
    };

    useEffect(() => {
        if (razorpayRes) {
            handleAddAddress(orderAddress)
        }
    }, [razorpayRes])

    const handlePayment = useCallback(async (amount) => {
        // let amount = cartAmount;
        const options = {
            key: siteConfig?.siteConfigurations?.razorpayKey ? siteConfig?.siteConfigurations.razorpayKey : "rzp_test_T8iOelkiEgvX5v",
            amount: amount * 100,
            key_secret: siteConfig?.siteConfigurations?.razorpaySecretKey ? siteConfig?.siteConfigurations.razorpaySecretKey : "mALClGtih9mZHWZebOq2JDel",
            currency: "INR",
            name: siteConfig?.name,
            description: `${siteConfig?.name}Gateway`,
            image: "https://example.com/your_logo",
            handler: (res) => {
                console.log(res);
                if (res) {
                    setRazorpayRes(res)
                }
            },
            prefill: {
                name: "Piyush Garg",
                email: "youremail@example.com",
                contact: "7695867430"
            },
            notes: {
                address: "Razorpay Corporate Office"
            },
            theme: {
                color: "#3399cc"
            }
        };
        const rzpay = new Razorpay(options);
        rzpay.open();
    }, [Razorpay, siteConfig]);

    const closeAlert = () => {
        setOpenAlert(false);
    }

    return (
        <React.Fragment>
            <SuccessAlert openAlert={openAlert} closeAlert={closeAlert} title="Order Confirmed" />
            {cartAmount > 0 ? <div style={{ textAlign: "center" }} >
                <button className="payment-button"
                    onClick={() => {
                       
                       if (orderAddress) {
                            handlePayment(cartAmount)
                        } else {
                            setAlertMessage(true)
                        }
                    }
                    }
                >PROCEED TO CHECKOUT</button>
            </div> : null}
            <Dialog
                style={{ width: "35%", margin: "auto" }}
                open={alertMessage}
                onClose={() => { setAlertMessage(false) }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please Fill Your Shipping Address To Buy Your Orders
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{ backgroundColor: "red", color: "black" }}
                        onClick={() => {
                            setAlertMessage(false)
                        }}
                        autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
