import React, { useContext, useEffect, useState } from 'react'
import { FaGreaterThan } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'semantic-ui-react'
import { addFiltered } from '../../slice/prductSlice';
import Box from '@mui/material/Box';
import { Button } from 'semantic-ui-react'
import Slider from '@mui/material/Slider';
import { gql, useQuery } from '@apollo/client';
import GlobalContext from '../../GlobalContext';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { CiStar } from "react-icons/ci";
import { SingleProduct } from '../SingleProduct';
import Allproduct from '../allproducts/Allproduct';
 import '../Home/home.css'
import { style } from '@mui/system';
const GET_MASTER_CATEGORIES = gql`
query MasterCategories($filter: MasterCategoryInput) {
    masterCategories(filter: $filter) {
      shopId
      category
      image
      id
      position
      status
      addedon
    }
  }
`;
const GET_TOP_PRODUCTS=gql`
query TopProducts($filter: top) {
  topProducts(filter: $filter) {
    id
    category
    description
    discount
    featureImage
    localName
    mastercategory
    name
    number
    prize
    productImage {
      image
      productId
    }
    tax
    wishList {
      like
    }
  }
}

`
function SelectPrice({handlecategory,catLen,filterScroll}) {
  const[search,setSearch]=useState('')
  const[data,setData]=useState([])
  const[items,setItems]=useState([])
  const[min,setMin]=useState(null)
  const {filterd}=useSelector(state=>state.data)
  const[reset,setReset]=useState(false)
  const context = useContext(GlobalContext);
  const [masterCategory, setMasterCategory] = useState([])
  const[category,setCategory]=useState([])
  const[max,setMax]=useState(null)
  const [openDialog,setOpenDialog]=useState(false)
  const[product,setProduct]=useState(null)
  const [topProduct,setTopProduct]=useState([])
  const {products}=useSelector(state=>state.data)
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    
  };
  const { loading: getMasterCategoriesLoading, error: getMasterCategoriesError, data: getMasterCategoriesData, refetch: getMasterCategoriesRefetch } = useQuery(GET_MASTER_CATEGORIES, {
    variables: {
        "filter": {
            "shopId": context.shopId,
        }
    },
});
const{loading:toploading,error:toperror,data:topProductsData,refetch:topRefetch}=useQuery(GET_TOP_PRODUCTS,{
  variables:{
    "filter": {
      "shopId": context.shopId,
     
    }
  }
})
   const dispatch=useDispatch()
   useEffect(()=>{
    if(getMasterCategoriesData && getMasterCategoriesData.masterCategories && context.shopId){
      setMasterCategory(getMasterCategoriesData.masterCategories)
      setCategory(getMasterCategoriesData.masterCategories?.map(cat=>cat?.category))
    }
    if(topProductsData && topProductsData.topProducts&&context.shopId){
       setTopProduct(topProductsData.topProducts)
    }
   },[getMasterCategoriesData,context.shopId,topProductsData])

   // Only run when items or dispatch change
  
  const handleSearch = () => {
    if (products.length > 0) {
    
      if(search==''){
       window.location.reload()
      }else{
        const filter= products.filter(pro =>
          pro?.name.toLowerCase().includes(search.toLowerCase()))
       if(filter.length>0){
        
        setItems(filter)
          filterScroll()
       }else {
        alert("no such product")
        filterScroll()
       }
      }
     
     
      
    }
  };
  useEffect(()=>{
    // if(products.length>0){
    //   const newObjofCategories=products?.reduce((acc,curr)=>{
    //     console.log(curr)
    //     if(!acc[curr?.category]){
    //       acc[curr?.category]=[]
    //     }
    //     acc[curr?.category].push(curr)
      
    //     return curr
    //   },{})
    //   console.log(newObjofCategories)
    // }
    console.log(products)
    
  },[products])
  const handleClose=()=>{
    setOpenDialog(false)
  }
useEffect(()=>{
const newfunc=()=>{

  const newArr=products?.map(item=>{
    if (!item) {
      return;
  }
  let prize = item?.prize;
  if (item?.discount > 0) {
      let discount = item?.discount
      prize = prize - discount;
  }
  if (item?.tax > 0) {
      let tax = ((prize * item.tax) / 100);
      prize = prize + tax;
  }
  return Math.floor(prize)
  })
   
const filtered=newArr?.filter(pro=>pro!=undefined)
const min=Math.floor(Math.min(...filtered))
const max=Math.floor(Math.max(...filtered))
setMin(min)
setMax(max)
setValue([min,max])

}
newfunc()
},[products])

const handleFilter=()=>{
  if(filterd?.length>0){
    
    const newFiltered=filterd?.filter(pro=>pro?.prize>=value[0] && pro?.prize<=value[1])
    console.log(newFiltered,"new")
    if(newFiltered.length==0){
      alert("no products available")
      setReset(true)
    }else
    setItems(newFiltered)
    filterScroll()
    // console.log(newFiltered,"195")
    setReset(true)
    return
  }else{
    const newproducts=products?.filter(pro=>pro?.prize>=value[0] && pro?.prize<=value[1])
    setItems(newproducts)
    filterScroll()
    console.log(newproducts,"201")
    setReset(true)
    
  }
  
}
useEffect(() => {
  if (items.length > 0) {
    dispatch(addFiltered(items)); // Dispatch the filtered items

  }
}, [items, dispatch]);

  return (
    <div style={{paddingLeft:"5%",display:"flex",flexDirection:"column",gap:"50px"}}>
     <div className='input' style={{width:"73%",display:"flex",gap:"2px",paddingTop:"3%"}}>
     <Input placeholder='Search Product...' onChange={e=>setSearch(e.target.value)} />
     <Button primary onClick={handleSearch} style={{padding:"0px 10px 0px 10px",border:"0"}}><FaGreaterThan/></Button>
     </div>
     <div>
      <p style={{fontSize:"22px",fontWeight:"bold"}}>Filter By Price</p>
     <Box sx={{ width: 270 ,marginBottom:"10px",marginLeft:"5px"}}>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        sx={{color:'black'}}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        min={min}
        max={max}
      />
    </Box>
<div style={{display:"flex",alignItems:"center",width:"100%"}}>
<div  style={{flex:"1"}}>
<Button className='but' onClick={handleFilter} primary>Filter</Button>
{reset && <Button onClick={()=>window.location.reload()} color='red'>Reset</Button>}
</div>
<div style={{flex:"1" , marginRight:"65px"}}>
<p>Price ₹{value[0]} - ₹{value[1]}</p>
</div>
</div>
<div>

</div>
     </div>
     <div style={{display:"flex",flexDirection:"column",justifyContent:"center",gap:"15px",marginRight:"70px"}}>
      <div>
      <p style={{fontSize:"22px",fontWeight:"bold"}}>Categories</p>
      </div>
     <div>
     <ul style={{listStyle:"none",display:"flex",flexDirection:"column",gap:"20px",fontSize:"18px"}}>
      
        <li key={category[0]?.id}  onClick={()=>{
          handlecategory(category[0])
          filterScroll()
          }} style={{display:"flex",alignItems:"center",justifyContent:"space-between",cursor:"pointer" ,marginLeft:"-25px"}}>{category[0]}<span style={{marginRight:"40px"}}>{`(${catLen?.accessories})`}</span></li>
        <li key={category[1]?.id} onClick={()=>{
          handlecategory(category[1])
          filterScroll()
          }} style={{display:"flex",alignItems:"center",justifyContent:"space-between",cursor:"pointer" ,marginLeft:"-25px"}}>{category[1]} <span style={{marginRight:"40px"}}>{`(${catLen?.mens})`}</span></li>
        <li key={category[2]?.id} onClick={()=>{
          handlecategory(category[2])
          filterScroll()
          }} style={{display:"flex",alignItems:"center",justifyContent:"space-between",cursor:"pointer" ,marginLeft:"-25px"}}>{category[2]}<span style={{marginRight:"40px"}}>{`(${catLen?.womens})`}</span></li>

    
        
      </ul>
     </div>
     </div>
     <div>
    
      <List sx={{ marginLeft:"-10px", width: '100%', maxWidth: 360, bgcolor: 'background.paper',paddingLeft:"10px" ,paddingRight:'10px',paddingTop:"10px",paddingBottom:"10px"}}>
      <p style={{fontSize:"22px",fontWeight:"bold"}}>Our Best Sellers</p>
      {topProduct?.map(item=>{
      return <>
      <ListItem style={{marginLeft:"-20px"}} alignItems="flex-start">
        <ListItemAvatar>
          <Avatar  alt="Remy Sharp" src={item?.featureImage ? "https://s3.ap-south-1.amazonaws.com/business.strackit.com/" + item?.featureImage:""} style={{ borderRadius: 0,cursor:"pointer"}}  onClick={()=>{
         
         setOpenDialog(true)
         setProduct(item)
       
       }}/>

        </ListItemAvatar>
        <ListItemText
       
        onClick={()=>{
         
          setOpenDialog(true)
          setProduct(item)
        
        }}
        style={{marginLeft:"2%",cursor:"pointer"}}
          primary={`${item.name}`}
          secondary={
            <React.Fragment>
              
              <Typography
                component="span"
                variant="body2"
                sx={{ color: 'text.primary', display: 'inline' ,fontSize:"17px"}}
              >
               ₹{Math.floor(item.prize)}
              </Typography>

            </React.Fragment>
          }
        />

      </ListItem>
      <Divider style={{marginLeft:"0px",width:"82%"}} variant="inset" component="li"  />

      </>

     
      })}

      </List>
     </div>
     <SingleProduct open={openDialog} handleClose={handleClose} product={product}  />

    </div>
  )
}

export default SelectPrice
