import React, { useRef, useState, useContext,useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Footer } from "../../Footer";
import { Navbar } from "../../Navbar";
import { CarosuelImage } from "../Carosuel";
import { Loader } from "../Loader";
import { MasterProduct } from "../MasterProducts";
import { SecondaryCategory } from "../SecondaryCategory";
import { gql, useQuery } from "@apollo/client";
import GlobalContext from "../../GlobalContext"
import Allproduct from "../allproducts/Allproduct";
import { Grid } from "semantic-ui-react";
import SelectPrice from "../SelectPrice/SelectPrice";
import './home.css'
import ParallaxEffect from "../paralax";
import Parallax from "../paralax/Parralax";

const GET_CAROUSEL = gql`
query Banners($filter: bannerFilterInput) {
    banners(filter: $filter) {
      id
      shop_id
      title
      image
      priority
      addedon
      link
    }
  }
`;



export const Home = () => {
    const [loading, setLoading] = useState(true)
   const[category,setCategory]=useState('')
    const [banners,setBanners] = useState([]);
    const[catLen,setCatLen]=useState(null)
    const context = useContext(GlobalContext);
    const filterRef=useRef()

    const filterScroll=()=>{
      filterRef.current.scrollIntoView({
        behavior:"smooth"
      })
    }

    const { loading: getCarouselLoading, error: getCarouselError, data: getCarouselData, refetch: getCarouselRefetch } = useQuery(GET_CAROUSEL, {
        variables: {
          "filter": {
            "shopId": context.shopId,
          }
        },
      });

    useEffect(()=>{
        if(getCarouselData){
            setLoading(false);
            setBanners(getCarouselData.banners)
        }
      },[getCarouselData])

   const handlecategory=(cat)=>{
    setCategory(cat)
   }
   const setCategoryLength=(len)=>{
    setCatLen(len)
    
   }
 
   console.log(category,"from home")
    if (loading) {
        return (
            <Loader />
        )
    }
    return (
        <div >
            <Navbar />
           
            <Parallax item={banners[0]}/>
            <div style={{ margin: "4% 1% 4% 1%"}}>
                <MasterProduct  />
                <div ref={filterRef}className="second-title" style={{textAlign:"center"}}>
                    PRODUCT OVERVIEW
                </div>
                {/* <SecondaryCategory /> */}
           
              <div className="filter" style={{display:"flex",width:"100%",justifyContent:"space-between",backgroundColor:"",padding:"10px 20px 10px 20px"}}>
               <div className="left"  style={{width:"30%"}}>
               <SelectPrice filterScroll={filterScroll}  catLen={catLen} handlecategory={handlecategory}/>
               </div>
              <div className="right" style={{width:"70%",marginTop:"10%",paddingBottom:"8%"}}>
              <Grid style={{width:"100%",position:"relative",right:"-20px"}}>
              <Allproduct  category={category} setCategoryLength={setCategoryLength}/>
                </Grid>
              </div>
              </div>
            
            </div>
            <ParallaxEffect item={banners[1]}/>
            <Footer />
        </div>
    )
}