import { createSlice } from "@reduxjs/toolkit"

const initialState={
    products:[],
    filterd:[]
}
const prductSlice=createSlice({
    name:"data",
    initialState,
    reducers:{
        addproducts:(state,action)=>{
            state.products=action.payload
        },
        addFiltered:(state,action)=>{
            state.filterd=action.payload
        }
    }
})

export const {addproducts,addFiltered} =prductSlice.actions;
export default prductSlice.reducer